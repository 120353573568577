<template>
 
  <div :class="`theme--${data.themeType=='TuktukStep1' ? 'Tuktuk' : data.themeType}`">
    <template v-if="data.themeType=='TuktukStep1'">
      <ProductDetails    
          @updateCartQuantity="updateCartQuantity"
          @addCartItem="addCartItem"
          @updateCartSummary="updateCartSummary"
          @setStep="setStep"
          @changeVariant="onChangeVariant"
          :data="{ ...data, productImages, variantImageIndex,changeVariant,changeVariantItem }"
          @addToCart="addToCart"
          @showMsg="showMsg"
          @applyDiscount="applyDiscount"
          @clearDiscount="clearDiscount"
          @updateDiscountLists="updateDiscountLists" 
        />
      
          <no-ssr>
            <Checkout      
              :is-mobile="isMobile"
              @updateCartQuantity="updateCartQuantity"
              :submited="submited"
              @onSubmitted="onSubmitted"
              @addCartItem="addCartItem"
              @updateCartTotal="updateCartTotal"
              @updateCartSummary="updateCartSummary"
              @calcCart="calcCart"
              @changeVariant="onChangeVariant"
              @setStep="setStep"
              @addToCart="addToCart"
              :shippingCourierItems="shippingCourierItems"
               :data="{ ...data, productImages, variantImageIndex , changeVariant,changeVariantItem}"
              @applyDiscount="applyDiscount"
              @clearDiscount="clearDiscount"
              @updateDiscountLists="updateDiscountLists" 
            />      
          </no-ssr>
      
    </template>
    <template v-else>
    <ProductDetails
          v-if="step == 1"
          @updateCartQuantity="updateCartQuantity"
          @addCartItem="addCartItem"
          @updateCartSummary="updateCartSummary"
          @setStep="setStep"
          @changeVariant="onChangeVariant"
          :data="{ ...data, productImages, variantImageIndex,changeVariant,changeVariantItem }"
          @addToCart="addToCart"
          @showMsg="showMsg"
          @applyDiscount="applyDiscount"
          @clearDiscount="clearDiscount"
          @updateDiscountLists="updateDiscountLists" 
        />
        <div v-else>
          <no-ssr>
            <Checkout      
              :is-mobile="isMobile"
              @updateCartQuantity="updateCartQuantity"
              :submited="submited"
              @addCartItem="addCartItem"
              @onSubmitted="onSubmitted"
              @updateCartTotal="updateCartTotal"
              @updateCartSummary="updateCartSummary"
              @calcCart="calcCart"
              @setStep="setStep"
              @addToCart="addToCart"
              @changeVariant="onChangeVariant"
              :shippingCourierItems="shippingCourierItems"
              :data="{ ...data, productImages, variantImageIndex , changeVariant,changeVariantItem}"
              @applyDiscount="applyDiscount"
              @clearDiscount="clearDiscount"
              @updateDiscountLists="updateDiscountLists" 
            />
      
          </no-ssr>
        </div>
    </template>
    
   
  </div>

</template>
<script>
import ProductDetails from "./ProductDetails/index.vue";
import Checkout from "./Checkout/index.vue";
import config from "./../../../config.js";
import Cookies from "js-cookie";

export default {
  props: ["data", "submited", "shippingCourierItems", "isMobile"],
  data() {
    return {
      step: 1,
      productImages: [],
      variantImageIndex: -1,
      baseUrl: config.api.replace("/api", ""),
      changeVariant:false,
      changeVariantItem:{}
    };
  },
  components: {
    ProductDetails,
    Checkout,
  },
  watch:{
    "data.requestedFields": {
      handler() {
        this.$emit("updateCheckout");
        // this.validate();
        // this.$emit("onValidate", true);
      },
      deep: true,
    },
    "data.variablePrices": {
      handler() {       
        this.updateProductImages();
        this.calcCart();
      },
      deep: true,
    },
    payload: {
      handler() {      
        this.$emit("updateCheckout");    
        this.calcCart();   
      },
      deep: true,
    },
    step: function() {
      if(this.data.nuxt2){
         window.scrollTo({ top: 0, behavior: 'instant' })
      }
     
    },
  },
  mounted() {
    this.updateProductImages();
    this.calcCart();
    // if (Cookies.get("tuktukSummaryCheckout") && !this.data.embed) {
    //   this.storeData = JSON.parse(Cookies.get("tuktukSummaryCheckout"));           
    //   if(this.storeData.url==this.data.url && this.$route.query.step && this.$route.query.step==2){
    //     this.setStep(2);
    //   }else{
    //     if(this.$route.query.step){
    //       this.$router.replace({ path: this.data.url, params: {url:this.data.url} });      
    //     }   this.$router.push({
        
    //     });
    //   }
    // }else{
    //   if(this.$route.query.step && !this.data.embed){
    //       this.$router.replace({ path: this.data.url, params: {url:this.data.url} });      
    //     } 
    // }
    

   
   
    
  },
  computed:{
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
  },
  methods: {
    updateDiscountLists(discounts,replace){
      this.$emit('updateDiscountLists',discounts,replace)
    },
    applyDiscount(discount){
      this.$emit("applyDiscount",discount);
    },
    updateCheckout(){
      this.$emit("updateCheckout");
    },
    clearDiscount(){
      this.$emit("clearDiscount");
    },
    calcCart() {
      // this.totalBumpOrderPrice = this.totalBumpOrderPrice ? this.totalBumpOrderPrice : 0
      let price =
        0 +
        parseFloat(this.data.payload.deliveryPrice ? this.data.payload.deliveryPrice : 0);
      this.data.cartSummary.map(async (e) => {
        let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
        if (e.whosaleData) {
          e.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
              subPrice = one.price * parseInt(e.qty);
            }
            return subPrice;
          });
        }
        price = price + subPrice;
      });
      this.$emit("updateTotalOriginal",price);
     
      if (this.data.payload.payment == "COD") {
        price = price + parseFloat(this.data.codPrice);
      }
    
      if (this.data.payload.uniquePrice && this.data.payload.payment == "bankTransfer") {
        price =
          price +
          (this.data.codeType == "Decrease"
            ? -this.data.payload.uniquePrice
            : this.data.payload.uniquePrice);
      }
     
      if (this.data.additionalCost && this.data.addShipping) {
        price = price + parseInt(this.data.additionalCost);
      }
      if (this.data.flatShippingPrice && this.data.shippingType == "flat") {
        price = price + parseInt(this.data.flatShippingPrice);
      }
      if (this.data.shippingDiscountValue && this.data.addShipping && this.data.shippingType == "automated" && this.data.shippingDiscountValue > 0) {
        price = price - parseFloat(parseFloat(this.data.shippingDiscountValue));
      }
      
      // if (this.payload.bump) {
      //   price = price + parseFloat(this.data.bumpPrice)
      // }

      if (
        this.data.isBump &&
        this.data.bumpArray &&
        this.data.bumpArray.length >= 0 &&
        this.data.selectedbumpArray
      ) {
        // price = price + parseFloat(this.data.bumpPrice)
        this.data.selectedbumpArray.map((bumpData) => {
          //  const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            price = price + (bumpData.bumpPrice ? parseFloat(bumpData.bumpPrice) : 0);
            //  this.totalBumpOrderPrice = this.totalBumpOrderPrice + parseFloat(bumpData.bumpPrice)
          }
        });
      }
      if (typeof price == "string") {
        price = parseFloat(price);
      }
   
      if(this.data.payload.discount["amount"] > 0){
        price = price-this.data.payload.discount["amount"]
      }    
      if(this.data.payload.automaticDiscount["amount"] > 0){
        price = price-this.data.payload.automaticDiscount["amount"]
      }   
      // return parseInt(price.toFixed())
      this.cartTotalPrice = parseInt(price.toFixed());
    },
    onChangeVariant(val){        
      if(val != undefined){
        if(typeof val=="object" && "status" in val){
          this.changeVariant=val.status;
          this.changeVariantItem=val.elem;
        }else{
          this.changeVariant=val;
        }
        if(!val){
          this.changeVariantItem={};
        }        
      }
     
    },
    setStep(count) {
      if((this.data.nuxt2 && process.client) || !this.data.nuxt2){    
      this.step = count;         
      if(count==2){    
        const storeData={
          selectedbumpArray:this.data.selectedbumpArray,
          cartQuantity:this.data.cartQuantity,
          payloadVariablePrices:this.data.payload.variablePrices,
          variablePrices:this.data.variablePrices.map(a=>{ return {qty:a.qty}}),
          cartSummary:this.data.cartSummary,
          url:this.data.url
        }    
        if(!this.data.embed){   
          Cookies.set("tuktukSummaryCheckout", JSON.stringify(storeData), {
            expires: new Date(new Date().getTime() + 30 * 60 * 1000),
          });
          if(!this.$route.query.step){
            this.$router.replace({ path:this.data.url, params: {url:this.data.url}, query: {step: count, ...this.$route.query} });      
          }   
        }     
      }else{
        if(!this.data.embed){
          this.$router.replace({ path: this.data.url, params: {url:this.data.url}})
        }
        
      }
    }
    },
    onSubmitted() {
      this.$emit("onSubmitted");
    },
    addToCart(val) {
      this.$emit("addToCart",val);
    },
    showMsg() {
      this.$emit("showMsg");
    },
    updateCartQuantity(cartQuantity) {
      this.$emit("updateCartQuantity", cartQuantity);
      this.calcCart();
    },
    addCartItem() {
      this.$emit("addCartItem");     
    },
    updateCartSummary(index) {     
      this.$emit("updateCartSummary", index);
      this.calcCart();
    },
    updateCartTotal(val) {
      this.$emit("updateCartTotal", val);
    },
    updateProductImages() {
      this.productImages = [];
      this.data.images.map((img) => {
        this.productImages.push({ src: img });
      });

      this.data.variablePrices.map((v) => {
        v.image && this.productImages.push({ src: v.image, name: v.name });
      });

      let itemName = [];
      Object.keys(this.data.payload.variablePrices).map((key) => {
        itemName.push(this.data.payload.variablePrices[key].name);
      });

      this.variantImageIndex = this.productImages.findIndex(
        (x) => x.name == itemName.join(" / ")
      );   
      // if (variantImageIndex > -1) {
      //   this.variantImageIndex = variantImageIndex;
      // }
    },
  },
};
</script>

<style lang="scss">
 @import "@/assets/styles/pages/success.scss";
 @import '@/assets/styles/pages/checkout/components/form/Tuktuk/index.scss';
 @import '@/assets/styles/pages/checkout/components/form/Tuktuk/Checkout/index.scss';
 @import '@/assets/styles/pages/checkout/components/form/Tuktuk/ProductDetails/index.scss';
</style>
