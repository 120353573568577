<template>
  <div
    id="checkout-content--right"
    :class="!isSizeWindow ? 'embed-checkout-right-wrapper' : ''"
  >
    <!-- PRODUCT GUARANTEE-->
    <template v-if="displayDiv(posName, 'guaranteeSeal')">
      <div
        v-if="
          data.showSeals &&
          ((data.themeType == 'Classic' &&
            data.templateType == 'RightSidebar') ||
            (data.themeType == 'Classic' &&
              data.templateType == 'LeftSidebar' &&
              !isSizeWindow))
        "
        class="checkout-content--right__guarantee"
      >
        <!-- <Seal :data='data' :isSizeWindow='isSizeWindow' :direction='column' /> -->
        <Seal :data="data" :isSizeWindow="isSizeWindow" />
      </div>
    </template>

    <div
      class="embed-checkout-right__container"
      :class="embed && popup ? 'embed-rightGroup' : ''"
    >
      <!-- PRODUCT VARIANT-->
      <template v-if="displayDiv(posName, 'productDetails')">
        <div
          v-if="data.type != 'simple'"
          class="checkout-content--right__feature checkout-content--right__product-variants"
        >
          <div
            v-if="data.marketPlaceStyle && isSizeWindow"
            class="product-variants__container--1"
          >
            <label class="text--label-field mb-10">{{ $t('common.chooseVariant')}}</label>
            <div
              class="product-variants__select-mobile"
              @click="openChooseVariantModal()"
            >
              <div class="product-variants__select-mobile__content">
                <div v-for="(attr, i) in data.attributes" v-bind:key="i">
                  {{ attr.value.length }} {{ attr.name
                  }}<span
                    v-if="i < data.attributes.length - 1"
                    style="margin-right: 1px"
                    >,</span
                  >
                </div>
              </div>
              <div class="product-variants__select-mobile__arrow">
                <img src="@/assets/icons/right-arrow.svg" />
              </div>
            </div>
          </div>

          <div v-else class="product-variants__container--2">
            <template v-if="data.multiplyVariations && !data.cartOrder">
              <div class="various-options">               
                <div v-if="data.attributes && data.attributes.length">
                  <label class="text--label-field mb-10">{{
                    data.titleVariations.replace(":", "")
                  }}</label>
                </div>

                <div class="options-group">
                  <div 
                    :key="'v' + i" 
                    v-for="(attr, i) in data.variablePrices.filter(x=>!x.delete)" 
                    class="variant-option-qty"
                  >
                    <div 
                      class="variant-option-qty__content" 
                      :class="isShowVariantImage?'variant-option-qty__content--show-thumbnail':''"
                    >
                      <div 
                        v-if="isShowVariantImage" 
                        class="variant-option-qty__content--show-thumbnail__thumbnail">
                        <img
                          :src="
                            attr.image
                              ? getSizedImageUrl(attr.image,'medium','product-images')
                              : require('@/assets/icons/variant-default-img.svg')
                          "
                          alt=""
                        />
                      </div>
                      <span class="variant-option-qty__content__label" :class="{'overflow-wrap--anywhere':isHasOneWordLongText(attr.name)}">
                        {{ attr.name }}
                      </span>
                    </div>
                    <div class="variant-option-qty__action">
                      <button
                        class="variant-option-qty__action__decrease"
                        @click="decrement(i, 'multiVariant')"
                      >
                        <img src="@/assets/icons/ic-action-decrease.svg" alt="-"/>
                      </button>
                      <!-- <vs-input
                        min="0"
                        v-model="attr.qty"
                        readonly
                      /> -->
                      <div class="variant-option-qty__action__number">{{ attr.qty }}</div>
                      <button
                        class="variant-option-qty__action__increase"
                        @click="increment(i, 'multiVariant')"
                      >
                        <img src="@/assets/icons/ic-action-increase.svg" alt="+"/>
                      </button>
                    </div>
                  </div>
                  <div v-if="errors && errors['zeroQTY']" class="invalid-feedback mt-0">
                    {{ errors['zeroQTY'] }}
                 </div>
                </div>
              </div>
            </template>

            <template v-else>
            
              <template
                v-if="
                  data.attributes &&
                  data.attributes.length &&
                  data.variationSelectType !== 'Radio'
                "
              >
                <h2 class="text--subtitle">
                  {{ data.titleVariations.replace(":", "") }}
                </h2>
              </template>  
                      
              <div
                v-for="(attr, i) in data.attributes"
                :key="'h' + i"
                class="product-variants__type"
              >
             
                <label class="text--label-field">{{ attr.name }}</label>
                <no-ssr> 
                <div
                  v-if="data.variationSelectType == 'Radio' && payload.variablePrices[attr.name + i.toString()]"
                  class="product-variants__type__item--radio"
                >
                 
                    <div
                      :key="'j' + j"
                      @click="
                        () =>
                          (payload.variablePrices[attr.name + i.toString()].name =
                            val)
                      "
                      class="product-variants__type__item--radio__option"
                     v-for="(val, j) in filteredProductVariant(attr.value, attr.name)"
                      :class="{'disabled-option':checkOption(attr.name,val),
                      'hide-variant':data.hideUnavailableVariant && checkOption(attr.name,val)
                    }"
                    >
                    
                      <vs-radio
                        v-model="
                          payload.variablePrices[attr.name + i.toString()].name
                        "
                        :val="val"
                        :disabled=checkOption(attr.name,val) 
                      >
                        <div>{{ val }}</div>
                      </vs-radio>
                  
                    </div>                 
                </div>
            
                <div
                  v-else-if="data.variationSelectType == 'Dropdown'"
                  class="options-group grid-cols-1"
                >             
                  <div v-if="payload.variablePrices[attr.name + i.toString()]">
                    <m-select class="w-100" v-model="payload.variablePrices[attr.name + i.toString()].name" :options="filteredProductVariant(attr.value, attr.name).map(value => ({ label: value, value }))" />
                  </div>
                </div>

                <div v-else class="product-variants__type__item--modern">
                      
                    <div v-for="(attrval, j) in filteredProductVariant(attr.value, attr.name)" :class="{'hide-variant':data.hideUnavailableVariant && checkOption(attr.name,attrval)}"  :key="j">
                      <selected-green-switch
                        v-if="payload.variablePrices[attr.name + i.toString()]"
                       
                        v-model="payload.variablePrices[attr.name + i.toString()].name"
                        :filterAttributes="filterAttributes"
                        :options="[
                          {
                            id: attrval,
                            text: attrval,
                            imgSrc: undefined,  
                            disabled:checkOption(attr.name,attrval),
                            hideUnavailableVariant: data.hideUnavailableVariant
                          },
                        ]"
                        full="99"                      
                      >
                      </selected-green-switch>
                    </div>                
                </div>
              </no-ssr>
              </div>

              <!-- Quantity in preview -->
              <template v-if="data.addQuantity">
                <div class="buyButton rightCom">
                  <div class="singleVariant-qty">
                    <label class="text--label-field">{{ $t('common.quantity') }}</label>
                    <div
                      class="col-5 flex increDecre-btn-wrap"
                      style="max-width: 125px; border-radius: 8px"
                    >
                      <div
                        class="minus-btn pointer"
                        @click="decrement(0, 'singleVariant')"
                      >
                        <div class="bg-pm-btn relative">
                          <div class="horizontal"></div>
                        </div>
                      </div>
                      <div
                        class="vs-form-control qty-input no-bg p-0 w-100 flex justify-center items-center"
                      >
                        {{ quantity }}
                        <!-- <vs-input min="0" disabled v-model="quantity" class="vs-form-control qty-input no-bg  p-0" /> -->
                      </div>
                      <div
                        class="plus-btn pointer"
                        @click="increment(0, 'singleVariant')"
                      >
                        <div class="bg-pm-btn relative">
                          <div class="horizontal"></div>
                          <div class="vertical"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>

            <!-- Add to cart -->
            <template
              v-if="data.multiplyVariations && data.cartOrder && !((data.variablePrices.length>0 && !isVariableItemSelected))"
              class="embed-checkout-right-cart row buyButton rightCom"
            >
              <div
                @click="addToCart"
                class="flex justify-center items-center w-100 text-white text-center pointer"
                :class="(data.variablePrices.length>0 && !isVariableItemSelected) ? 'buy-button__default--disabled' :''"
                style="min-height: 55px; border-radius: 8px"
                :style="
                  'background-color: ' +
                  data.buyButtonStyle +
                  (loadingPrice ? '; opacity: 0.5;' : '')
                "
                 :disabled="(data.variablePrices.length>0 && !isVariableItemSelected)"  
              >
                <span
                  :class="{
                    'bold-buy-btn': data.boldBuyButtonText,
                    'small-buy-btn': data.buyButtonFontSize == 'Small',
                    'medium-buy-btn': data.buyButtonFontSize == 'Medium',
                    'large-buy-btn': data.buyButtonFontSize == 'Large',
                    'extra-large-buy-btn':
                      data.buyButtonFontSize == 'Extra-Large',
                  }"
                >
                  {{ $t('common.addToCart') }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </template>

     
        <!-- RECIPIENT -->
        <div
          v-if="displayDiv(posName, 'requestedFields')"
          class="checkout-content--right__feature checkout-content--right__recipient"
        >
          <h2 class="text--subtitle">{{ data.titleForm }}</h2>
          <div class="recipient__form-container">
            <template
              v-for="(row, index) in data.requestedFields"
            >
              <template v-if="row.active">
                <div
                  v-if="showFields(index)"
                  :key="row.label+index"
                  class="recipient__form__field focus-active-field position-relative m-0"
                  :ref="row.field"
                  style="scroll-margin: 100px;"
                >
                  <label
                    v-if="data.isLabel == 'showLabel'"
                    class="text--label-field"
                  >
                    {{ row.label }}
                    {{
                      row.required == "yes"
                        ? ""
                        : data.formMode !== "Progressive Form"
                        ? "(Opsional)"
                        : ""
                    }}
                  </label>

                  <div
                    v-if="row.field == 'address' && row.key == 'ENTER_CITY_NAME'"
                    class="address-fields"
                    :class="{
                      'is-invalid': errors[row.field],
                      'adjust-input-field': data.isLabel == 'showLabel',
                    }"
                  >
                    <AddressFill
                      :className="{
                        'is-invalid': errors[row.field],
                        'vs-form-control': true,
                      }"
                      @onChange="changeAddress"
                      :placeholder="row.placeholder"
                      :dontIncludeSubdistrict="payload.dontIncludeSubdistrict"
                      :dontIncludeZipcode="payload.dontIncludeZipcode"
                      :fieldName="row.key"
                      :addressVal="payload.address"
                      :shippingData="{'enableExcludeShipping':data.enableExcludeShipping,'excludeShipping':data.excludeShipping,'excludeShippingLocation':data.excludeShippingLocation}"
                    />

                    <!-- <div style="margin-top: 10px" class="village-checkbox">
                      <vs-checkbox v-if="row.label == 'Kota / Kecamatan'" v-model="payload.dontIncludeSubdistrict">Tanpa
                        Kelurahan</vs-checkbox>
                    </div> -->
                  </div>

                  <div
                    v-else-if="
                      row.field == 'address' && row.key != 'ENTER_CITY_NAME'
                    "
                    class="subAddress-fields"
                    :class="{ 'is-invalid': errors[row.field] }"
                  >
                    <SubAddress
                      :className="{
                        'is-invalid': errors[row.field],
                        'vs-form-control': true,
                      }"
                      @onChange="changeAddress"
                      :placeholder="row.placeholder"
                      :dontIncludeSubdistrict="payload.dontIncludeSubdistrict"
                      :dontIncludeZipcode="payload.dontIncludeZipcode"
                      :fieldName="row.key"
                      :isLabel="data.isLabel"
                      :addressVal="payload.address"
                      :formMode="data.formMode"
                      :shippingData="{'enableExcludeShipping':data.enableExcludeShipping,'excludeShipping':data.excludeShipping,'excludeShippingLocation':data.excludeShippingLocation}"
                    />
                  </div>

                  <template v-else-if="row.isCustom">
                    <template v-if="row.fieldType == 'input'">
                      <vs-input
                        v-if="row.inputType == 'number'"
                        class="vs-form-control"
                        v-model="row.value"
                        :class="{ 'is-invalid': errors[row.field] }"
                        type="number"
                        :placeholder="row.placeholder"
                      />
                      <template  v-else-if="row.inputType == 'date'">
                          <no-ssr>
                          <date-picker                          
                            :placeholder="row.placeholder"
                            :class="{ 'is-invalid': errors[row.field] }"
                            v-model="row.value"
                            type="date"
                          >
                          </date-picker>
                        </no-ssr>
                      </template>
                      <div v-else-if="row.inputType == 'file'">
                        <FileUploader
                          type="file"
                          :className="{
                            'is-invalid': errors[row.field],
                            'vs-form-control': true,
                          }"
                          v-model="row.value"
                        >
                        </FileUploader>
                      </div>

                      <vs-input
                        v-else
                        class="vs-form-control"
                        v-model="row.value"
                        :class="{ 'is-invalid': errors[row.field] }"
                        type="text"
                        :placeholder="row.placeholder"
                      />
                    </template>

                    <div
                      v-else-if="row.fieldType == 'textarea'"
                      class="vs-form-control"
                    >
                      <textarea
                        :placeholder="row.placeholder"
                        :class="{ 'is-invalid': errors[row.field] }"
                        v-model="row.value"
                        id="subject"
                        rows="3"
                        cols="50"
                      ></textarea>
                    </div>

                    <div v-else :class="{ 'is-invalid': errors[row.field] }">
                      <vs-select
                        :placeholder="row.placeholder"
                        class="vs-form-control"
                        :class="{ 'is-invalid': errors[row.field] }"
                        v-model="row.value"
                      >
                        <vs-option
                          :key="val + j + 'f'"
                          v-for="(val, j) in row.options"
                          :label="val.name"
                          :value="val.name"
                          >{{ val.name }}</vs-option
                        >
                      </vs-select>
                    </div>
                  </template>

                  <vs-input
                    v-else-if="row.field == 'quantity'"
                    min="1"
                    max="100"
                    class="vs-form-control"
                    v-model="row.value"
                    :class="{ 'is-invalid': errors[row.field] }"
                    type="number"
                    :placeholder="row.placeholder"
                  />

                  <vs-input
                    v-else-if="row.field == 'phone'"
                    class="vs-form-control phone-input"
                    v-model="row.value"
                    :class="{ 'is-invalid': errors[row.field] }"
                    :type="isMobile ? 'number' : 'text'"
                    :placeholder="row.placeholder"
                    pattern="[0-9]*"
                    v-on:blur="() => formatPhone()"
                  />

                  <template v-else-if="row.field == 'addressText'">
                    <textarea
                      v-if="row.fieldType == 'textArea'"
                      :placeholder="row.placeholder"
                      :class="{ 'is-invalid': errors[row.field] }"
                      v-model="row.value"
                      class="addressText-textarea vs-form-control"
                      id="subject"
                      rows="5"
                    ></textarea>
                    <vs-input
                      v-else
                      class="vs-form-control"
                      v-model="row.value"
                      :class="{ 'is-invalid': errors[row.field] }"
                      type="text"
                      :placeholder="row.placeholder"
                    />
                  </template> 

                  <vs-input
                    v-else
                    class="vs-form-control"
                    v-model="row.value"
                    :class="{ 'is-invalid': errors[row.field] }"
                    type="text"
                    :placeholder="row.placeholder"
                  />

                  <div v-if="errors[row.field] && row.field == 'address'" class="invalid-feedback mt-5px">
                    {{ payload[row.field] && !payload[row.field][row.key.replace('_NAME', '')] ? errors[row.field] : !payload[row.field] ? errors[row.field] : '' }}
                  </div>
                  <div v-else-if="errors[row.field]" class="invalid-feedback mt-5px">
                    {{ errors[row.field] }}
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
     

      <!-- SAVE INFORMATION -->
      <template v-if="displayDiv(posName, 'saveThisInformation') && !data.saveInfoWithoutEnable">
        <div
          v-if="data.isSaveInfoNextTime"
          class="checkout-content--right__feature save-information"
        >
       
          <vs-checkbox @click="saveDataForNexttime()">
            <div style="text-align: -webkit-left; margin-left: 8px;">
              {{ data.saveInfoLabel ? data.saveInfoLabel : $t("common.saveInfoNextTime") }}
            </div>
            
            <!-- {{ $t("common.saveInfoNextTime") }} -->
          </vs-checkbox>
        
        </div>
      </template>

      <!-- START SHIPPING -->

      <!-- SHIPPING AUTOMATED -->
      <div
        v-if="
          data.addShipping &&
          (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
          payload.address &&
          courierOptionDataFinal &&
          courierOptionDataFinal.length > 0 &&
          displayDiv(posName, 'shipping') &&
          (courierOptionDataFinal.length > 1 ||
            (courierOptionDataFinal.length == 1 && !data.shippingHideView))
        "
        class="checkout-content--right__feature checkout-content--right__shipping--automated"
      >
        <!-- Pre Order -->
        <div v-if="data.preOrder != undefined && data.preOrder.isActive"  class="pre-order flex items-center" style="padding-top: 8px; padding-bottom: 8px;">
          <img src="@/assets/icons/calendar-wait.svg" alt="Calendar" />
          <span class="fs-13 fw-500">{{ preOrderText }}</span>
        </div>
        <h2 class="text--subtitle">{{ data.titleShipping }}</h2>
        <div class="row">
          <div class="col-12">
            <vs-select
              class="vs-form-control"
              v-model="deliveryCourierPrice"
              :placeholder="'Pilih Ekspedisi'"
              @change="changeCouriePrice"
              :class="{ 'is-invalid': errors['deliveryCourierPrice'] }"
              v-if="courierOptionDataFinal.length > 0"
              :key="id"
            >
              <vs-option
                v-for="(options, ind) in courierOptionDataFinal"
                :label="options.label2"
                :value="options.value"
                v-bind:key="`shipping-${ind}`"
                class="courierOptionsWithPrice"
              >
                <div class="left_content">
                  <div class="d-flex items-center">
                    <h6 class="types-ofcourier m-0">{{ options.label.split('-').length ? options.label.split('-')[0]+'-'+options.label.split('-')[1] : '' }}</h6>
                    <div v-if="data.multiShipFromAddress && isCheapest(options.label,courierOptionDataFinal)">
                      <span class="cheapest-label ml-1">{{ $t("common.cheapest") }}</span>
                    </div>
                  </div>
                  <p v-if="options.shipFromAddress" class="shipping-address m-0">({{ options.shipFromAddress ? 'Dikirim dari ' + options.shipFromAddress.CITY_NAME : ''}})</p>
                </div>
                <div>
                  <h6 class="shipping-price m-0">{{ options.label.split('-').length ? options.label.split('-')[2] : 'Rp0'}}</h6>
                </div>
              </vs-option>
            </vs-select>
            <div
              v-if="errors['deliveryCourierPrice']"
              class="invalid-feedback mt-5px"
            >
              {{ errors["deliveryCourierPrice"] }}
            </div>

            <!-- <vs-select
              v-if="
                delivery.jne.length ||
                delivery.sicepat.length ||
                delivery.sap.length ||
                delivery.iDexpress.length
              "
              class="vs-form-control"
              v-model="payload.deliveryCourier"
            >
              <vs-option
                :style="delivery.jne.length ? '' : 'display: none'"
                :label="'JNE'"
                :value="'jne'"
                >JNE
              </vs-option>
              <vs-option
                :style="delivery.sicepat.length ? '' : 'display: none'"
                :label="'SiCepat'"
                :value="'sicepat'"
              >
                SiCepat</vs-option
              >
              <vs-option
                :style="delivery.sap.length ? '' : 'display: none'"
                :label="'Sap'"
                :value="'sap'"
                >Sap
              </vs-option>
              <vs-option
                :style="delivery.iDexpress.length ? '' : 'display: none'"
                :label="'iDexpress'"
                :value="'iDexpress'"
                >iDexpress
              </vs-option>
              <vs-option
                :style="delivery.jNt.length ? '' : 'display: none'"
                :label="'J&T Express'"
                :value="'jNt'"
                >J&T Express
              </vs-option>
            </vs-select> -->
          </div>
          <!-- <div class="col-6">
            <div
              v-if="delivery[payload.deliveryCourier].length"
              class="fake-select-value"
              style="top: 9px; left: 26px"
            >
              REG - Rp{{
                payload.deliveryPrice
                  ? payload.deliveryPrice
                  : delivery[payload.deliveryCourier][0].price | g_number_format
              }}
            </div>
            <vs-select
              :key="'pr' + i"
              v-for="(price, i) in delivery[payload.deliveryCourier]"
              class="vs-form-control"
              v-model="payload.deliveryPrice"
            >
              <vs-option :value="price.price"
                >REG - Rp{{ price.price | g_number_format }}</vs-option
              >
            </vs-select>
          </div> -->
        </div>
      </div>

      <!-- SHIPPING FLAT AND FREE-->
      <no-ssr>
      <div
        v-if="
          data.addShipping &&
          (payload.payment === 'COD' ? data.shippingCOD.shippingType !== 'automated' : data.shippingType !== 'automated') &&
          displayDiv(posName, 'shipping')
        "
        class="checkout-content--right__feature checkout-content--right__shipping--flat-or-free"
      >
        <template v-if="payload.payment === 'COD' ? data.shippingCOD.shippingType === 'flat' : data.shippingType == 'flat'">
          <label class="text--label-field">{{ data.titleShipping }}</label>
          <div class="attributes mt-10" style="padding: 15px">
            <div class="attribute flex justify-between items-center">
              <div class="d-flex justify-content-center">
                <div class="flat-free-title">
                  <span
                    v-if="(payload.payment === 'COD' ? data.shippingCOD.shippingType === 'flat' : data.shippingType == 'flat') && 
                    (payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice)"
                  >
                    Rp{{ payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice | g_number_format }}
                  </span>
                </div>
                <!-- display Strikethrough Shipping price-->
                <div class="text-danger ml-1"
                  v-if="freeDeliveryPrice !== '' && data.isShowStrikethrough && ((payload.payment === 'COD' ? Number(data.shippingCOD.flatShippingPrice || 0) : Number(data.flatShippingPrice || 0)) < freeDeliveryPrice)"
                  style="text-decoration: line-through;">
                  Rp{{ freeDeliveryPrice | g_number_format }}
                </div>
              </div>

              <div class="shippingType-title">
                {{ $t('common.shipping') }} {{ payload.payment === 'COD' ? data.shippingCOD.shippingType : data.shippingType  }}
              </div>
            </div>
          </div>
        </template>

        <!-- FREE -->
        <template v-if="payload.payment === 'COD' ? data.shippingCOD.shippingType === 'free' : data.shippingType == 'free'">
          <label class="text--label-field">{{ data.titleShipping }}</label>
          <div class="attributes mt-10" style="padding: 15px">
            <div class="attribute flex justify-between items-center">

              <div class="d-flex justify-content-center">
                <div class="flat-free-title">Rp0</div>
                <!-- display Strikethrough Shipping price-->
                <div class="text-danger ml-1" v-if="freeDeliveryPrice != '' && data.isShowStrikethrough" style="text-decoration: line-through;"> Rp{{ freeDeliveryPrice | g_number_format }}
                </div>
              </div>
              
              <div class="free-shippingType-title">
                {{ payload.payment === 'COD' ? data.shippingCOD.shippingType : data.shippingType }} {{ $t('common.shipping') }}
              </div>
            </div>
          </div>
        </template>
      </div>
      </no-ssr>

      <!-- END SHIPPING -->
   
      <template v-if="displayDiv(posName, 'otherContent')">
        <div
          v-if="data.showOtherContent && data.otherContent" 
          class="OtherContent_wrapper">
          <div 
            v-if="data.otherContent"
            class="add-product-preview-right-description text-left text-break"
            v-html="data.otherContent"></div>
        </div>
      </template>
    
      <!-- PAYMENT METHOD -->
      <no-ssr>
        <template
          v-if="
            displayDiv(posName, 'payment') &&
            data.showPayment &&
            data.paymentType &&
            data.paymentType.find((p) => p.active) &&
            (data.paymentType.filter((p) => p.active).length > 1 ||
              !showPaymentHideView)
          "
        >
          <div
            class="checkout-content--right__feature embed-checkout-right-payment embed-payment-method"
            :class="isSizeWindow ? 'after_bg_payment' : ''"
          >
            <h2 class="text--subtitle">{{ data.titlePayment }}</h2>
            <div class="attributes embed-paymentType mt-10">
              <template
                v-for="(pType, ind) in data.paymentType.filter((p) => p.active)"
              >
                <div
                  v-bind:key="ind"
                  v-if="pType.type != 'E-Payment'"
                  class="attribute flex justify-between items-center"
                  @click="changeMode(pType.name)"
                >
              
                  <vs-radio :val="pType.type" v-model="payload.payment">
                    <img
                      width="42px"
                      height="30px"
                      alt=""
                      class="payment-image mr-10"
                      :src="
                        pType.type == 'COD'
                          ? require('@/assets/icons/Payments/payment-cod.png')
                          : require('@/assets/icons/Payments/payment-bank_transfer.png')
                      "
                    />{{ pType.name }}
                  </vs-radio>
              
                </div>
                <template v-for="(elem, i) in data.epaymentSettings" v-else>
                  <div
                    class="attribute flex justify-between items-center"
                    :key="ind + i"
                    v-if="elem.active"
                    @click="changeMode('E-Payment')"
                  >
                
                  <vs-radio
                  v-bind:key="i"
                  :val="elem.id"
                  v-model="payload.payment"
                  >
                    <div class="flex items-center gap-10px">
                      <img
                          width="42px"
                          height="30px"
                          alt=""
                          class="payment-image mr-10"
                          :src="
                            pType.id == 'COD'
                              ? require('@/assets/icons/Payments/payment-bank_transfer.png')
                              : require('@/assets/icons/Payments/' +
                                  elem.id +
                                  '.png'
                                  ? '@/assets/icons/Payments/' + elem.id + '.png'
                                  : '@/assets/icons/Payments/' + elem.id + '.svg')
                          "
                        />
                        <div>
                          <p class="m-0" style="font-size: 15px;">{{ elem.label }}</p>
                          <template v-if="data.ePaymentFeePaidBy === 'buyer'">
                            <p class="m-0 e-payment-admin-fee" v-if="elem.id === 'gopay'">B{{ $t('settings.adminEPayFeeGopay') }}</p>
                            <p class="m-0 e-payment-admin-fee" v-else-if="elem.id === 'QRIS'">{{ $t('settings.adminEPayFeeQRIS') }}</p>
                            <p class="m-0 e-payment-admin-fee" v-else-if="elem.id === 'creadit_card'">{{ $t('settings.adminEPayFeeCreditCard') }}</p>
                            <p class="m-0 e-payment-admin-fee" v-else>{{ $t('settings.adminEPayFeeOther') }}</p>
                          </template>
                        </div>
                      </div>
                    </vs-radio>
                  
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </no-ssr>
  
      <!-- BUMP PRODUCT -->
      <template v-if="displayDiv(posName, 'bump')">
        <div
          v-if="
            data.showBumpProduct && data.isBump && data.bumpArray.length > 0
          "
          class="checkout-content--right__feature checkout-content--right__bump"
        >
          <div
            v-for="(bumpData, i) in data.bumpArray"
            :key="i"
            class="embed-checkout-right__bump__item"
            :style="
            bumpData.bumpBorder &&
            bumpData.bumpColor &&
            bumpData.bumpBackground &&
            `background-color:${bumpData.bumpBackground};
             border-color:${ bumpData.bumpBorder};
             color:${ bumpData.bumpColor};
            `
          
          "
          @click="(e)=>{addBumpOrder(e,bumpData)}"
          >
            <div
              class="bump__item__main"
              :class="
                bumpData.bumpImage ? 'bump__item__main--thumbnail-show' : ''
              "
            >
              <div
                class="bump__item__main__thumbnail"
                v-if="bumpData.bumpImage"
              >
                <img
                  style="min-width: 75px"
                  :src="
                    getSizedImageUrl(bumpData.bumpImage,'thumbnail','product-images')
                  "
                />
              </div>
              <div class="bump__item__main__info">
                <div class="bump__item__main__info__title-price">
                  <p :style="           
                    bumpData.bumpColor &&           
                    `color:${ bumpData.bumpColor};
                    ` ">{{ bumpData.bumpName }}</p>
                  <p :style="           
                    bumpData.bumpColor &&           
                    `color:${ bumpData.bumpColor};
                    ` ">Rp {{ bumpData.bumpPrice | g_number_format }}</p>
                </div>
                <p
                  v-if="bumpData.bumpDescription"
                  class="bump__item__main__info__description"
                  :style="           
                    bumpData.bumpColor &&           
                    `color:${ bumpData.bumpColor};
                    ` "
                >
                  {{ bumpData.bumpDescription }}
                </p>
              </div>
            </div>

            <div class="bump__item__action--buy" :style="`border-color: ${bumpData.bumpBorder}`">
              <div
                class="d-flex flex-row bump_input_wrapper justify-content-between w-100"
              >
             
                <vs-checkbox
                  v-model="selectedbumpArray"
                  :key="i"
                  :val="bumpData"
                >
                 <label :style="           
                    bumpData.bumpColor &&           
                    `color:${ bumpData.bumpColor};
                    ` ">{{ bumpData.bumpText3 }}</label> 
                </vs-checkbox>
                <input
                  type="hidden"
                  disabled
                  class="checkbox-text bumpText3 ml-2"
                  v-model="bumpData.bumpText3"
                />
             
              </div>
            </div>
          </div>
        </div>
      </template>
    
      <!-- LEFT COMPONENT -->
      <no-ssr>
      <div
        v-if="
          data.templateType == 'RightSidebar' &&
          data.themeType == 'Classic' &&
          isSizeWindow &&
          !embed
        "
      >
        <!-- Buybutton in between -->
        <!-- <template v-if="displayDiv(posName, 'buyButton')">
          <div :style="isSizeWindow && cartSummary.length && data.cartOrder ? 'padding-bottom:20px;' : 'flex: 0 0 100%; width: 100%; padding-bottom:20px; margin: auto;'" v-if="displayDiv(posName, 'buyButton')">
            <div v-if="isDisabled" style="border-radius: 5px; background-color: #e0e0e0"
              class="w-100 px-2 py-1 text-white text-cente">
              <div style="color: rgba(0, 0, 0, 0.26)" class="flex items-center justify-center">
                Stok Produk Kosong
              </div>
            </div>
            <div v-else-if="showCaptcha && !verifiedCaptcha" style="border-radius: 5px; background-color: #e0e0e0; "
              class="w-100 px-2 py-1 text-white text-center">
              <div style="color: rgba(0, 0, 0, 0.26); min-height: 55px" class="flex items-center justify-center">
              {{ data.buyButtonText }}
              </div>
            </div>
            <div v-else @click="() => !loadingPrice && save()"
              class="flex justify-center items-center w-100 text-white text-center pointer"
              style="min-height: 55px; border-radius: 8px;"
              :style="'background-color: ' + data.buyButtonStyle + (loadingPrice ? '; opacity: 0.5;' : '')">
              <span :class="{
                'bold-buy-btn': data.boldBuyButtonText,
                'small-buy-btn': data.buyButtonFontSize == 'Small',
                'medium-buy-btn': data.buyButtonFontSize == 'Medium',
                'large-buy-btn': data.buyButtonFontSize == 'Large',
                'extra-large-buy-btn': data.buyButtonFontSize == 'Extra-Large',
              }">
                {{ data.buyButtonText }} </span>
                <div v-if="data.isAddIcon" class="ml-2 d-flex justify-content-center">
                    <vue-feather :type="data.selectedIconName"></vue-feather>
                </div>
            </div>
          </div>
        </template> -->       
        <Left
          :selectedbumpArray="selectedbumpArray"
          :data="data"
          :form="form"
          :cartTotal="cartTotal"
          :payload="payload"
          :codPrice="codPrice"
          :priceWithoutWhosale="priceWithoutWhosale"
          :loadingPrice="loadingPrice"
          :delivery="delivery"
          :price="price"
          :weight="weight"
          :quantity="quantity"
          @updateCartSummary="removeFromCart"
          :totalPrice="totalPrice"
          :isSizeWindow="isSizeWindow"
          :cartSummary="cartSummary"
          @onSave="save"
          :pos="pos"
          :posName="posName"
          :posIndex="posIndex"
          :productImages="productImages"
          :variantImageIndex="variantImageIndex"
          :shippingDiscountValue="shippingDiscountValue"
        />
      </div>

      <!-- COUPON -->    
    <template v-if="data.showCoupon && discountLists && discountLists.length>0 && displayDiv(posName, 'coupon')">
      <Coupon :data="data" :payload="payload" @selectDiscount="selectDiscount" @updateDiscountLists="updateDiscountLists" @clearDiscountCode="clearDiscountCode" :discountLists="discountLists" :totalQuantity="totalQuantity" :cartSummary="cartSummary" :totalPrice="totalPrice" :price="price"/>
    </template> 


      <!-- BUY BUTTON BEFORE SUMMERY -->
      <template v-if="displayDiv(posName, 'buyButton')">
        <div v-if="data.summaryPlacement == 'After Buy Button'">
          <div
            class="checkout-content--right__feature embed-checkout-right-button embed-buyButton flex align-items-center grid-gap15px"
            v-if="
              data.themeType == 'Classic' ||
              (data.themeType != 'Classic' &&
                data.templateType != 'RightSidebar') ||
              (data.themeType != 'Classic' &&
                data.templateType == 'RightSidebar' &&
                !isSizeWindow)
            "
          >
             <div
              v-if="isSizeWindow && cartSummary.length && data.cartOrder"
              style="
                flex: 0 0 56px;
                width: 56px;
                background: #ffffff;
                border: 1px solid #bfcee0;
                border-radius: 8px;
                max-height: 56px;
                height: 100%;
                padding: 17px;
              "
              @click="openCartSummary()"
            >
              <div
                class="flex justify-content-center align-items-center position-relative"
              >
                <div class="cart-counter">
                  {{ cartSummary ? cartSummary.length : 0 }}
                </div>
                <img src="@/assets/icons/Cart-bucket.svg" alt="" />
              </div>
            </div>

            <div
              :style="
                isSizeWindow && cartSummary.length && data.cartOrder
                  ? 'flex: 0 0 calc(100% - 70px); width: calc(100% - 70px);'
                  : 'flex: 0 0 100%; width: 100%;'
              "
            >
              <div
                v-if="isDisabled"
                style="
                  border-radius: 5px;
                  background-color: #e0e0e0;
                  min-height: 55px;
                "
                class="w-100 px-2 py-1 text-white text-cente"
              >
                <span
                  class="flex items-center justify-center"
                  style="color: rgba(0, 0, 0, 0.26)"
                  :class="{
                    'bold-buy-btn': buyButton.boldBuyButtonText,
                    'small-buy-btn': buyButton.buyButtonFontSize == 'Small',
                    'medium-buy-btn': buyButton.buyButtonFontSize == 'Medium',
                    'large-buy-btn': buyButton.buyButtonFontSize == 'Large',
                    'extra-large-buy-btn':
                      buyButton.buyButtonFontSize == 'Extra-Large',
                  }"
                >
                  {{ $t('common.stockEmpty') }}
                </span>
              </div>
              <div
                v-else-if="showCaptcha && !verifiedCaptcha"
                style="
                  border-radius: 5px;
                  background-color: #e0e0e0;
                  min-height: 55px;
                "
                class="w-100 px-2 py-1 text-white text-center"
              >
                <span
                  style="color: rgba(0, 0, 0, 0.26)"
                  class="flex items-center justify-center"
                  :class="{
                    'bold-buy-btn': buyButton.boldBuyButtonText,
                    'small-buy-btn': buyButton.buyButtonFontSize == 'Small',
                    'medium-buy-btn': buyButton.buyButtonFontSize == 'Medium',
                    'large-buy-btn': buyButton.buyButtonFontSize == 'Large',
                    'extra-large-buy-btn':
                      buyButton.buyButtonFontSize == 'Extra-Large',
                  }"
                >
                  {{ buyButton.buyButtonText }}
                </span>
              </div>

              <!-- for show floating button  -->
              <div
                v-else-if="isSizeWindow && data.floatingButton"
                @click="() => !loadingPrice && save()"
                class="flex justify-center items-center pointer text-white m_float_btn"
                style="min-height: 55px; border-radius: 8px"
                :style="
                  'background-color: ' +
                  buyButton.buyButtonStyle +
                  ';border: 1px solid ' +
                  buyButton.buyButtonStyle +
                  (isBuyButtonLoading | loadingPrice
                    ? '; opacity: 0.5; pointer-event: none; cursor: default;'
                    : '')
                "
                :disabled="isBuyButtonLoading || loadingPrice"
              >
                <div
                  v-if="data.isAddIcon"
                  class="d-flex justify-content-center"
                >
                  <vue-feather :type="data.selectedIconName"></vue-feather>
                </div>
                <div v-else class="d-flex justify-content-center">
                  <i data-v-07452373="" data-v-62b2fe6e="" data-name="shopping-cart" data-tags="ecommerce,cart,purchase,store" data-type="shopping-cart" class="feather feather--shopping-cart"><svg data-v-07452373="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart feather__content"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg></i>
                </div>
              </div>

              <button
                v-else
                @click="() => !loadingPrice && save()"
                class="buy-button__default"
                :class="[
                  buyButton.isAddIcon ? 'buy-button__default--show-icon' : '',
                  isBuyButtonLoading ? 'buy-button__default--loading' : '',
                  (data.variablePrices.length>0 && !isVariableItemSelected) ? 'buy-button__default--disabled' :''
                ]"
                :style="
                  'background-color: ' +
                  buyButton.buyButtonStyle +
                  ';' +
                  'border: 1px solid ' +
                  buyButton.buyButtonStyle
                "
                :disabled="isBuyButtonLoading || loadingPrice || (data.variablePrices.length>0 && !isVariableItemSelected)"
              >
                <div class="buy-button__default__ic-loading">
                  <img
                    src="@/assets/icons/ic-loading-button.svg"
                    alt="loading"
                  />
                </div>
                <span
                  :class="{
                    'bold-buy-btn': buyButton.boldBuyButtonText,
                    'small-buy-btn': buyButton.buyButtonFontSize == 'Small',
                    'medium-buy-btn': buyButton.buyButtonFontSize == 'Medium',
                    'large-buy-btn': buyButton.buyButtonFontSize == 'Large',
                    'extra-large-buy-btn':
                      buyButton.buyButtonFontSize == 'Extra-Large',
                  }"
                >
                  {{ buyButton.buyButtonText }}
                </span>
                <template v-if="buyButton.isAddIcon">
                  <vue-feather :type="buyButton.selectedIconName"></vue-feather>
                </template>
              </button>
            </div>
          </div>
        </div>
      </template>
   
      <!-- Order summary for no sidebar -->
      <template v-if="displayDiv(posName, 'orderSummary')">
        <div
          v-if="
            data.orderSummary &&
            payload &&
            data.themeType == 'Classic' &&
            ((data.templateType == 'NoSidebar' || data.templateType == 'NoSidebarNarrowCanvas') ||
              (data.templateType !== 'NoSidebar' && data.templateType !== 'NoSidebarNarrowCanvas' && embed))
          "
          class="checkout-content--right__feature pp-order-summary"
          :class="
            data.orderSummary &&
            payload &&
            data.themeType == 'Classic' &&
            data.templateType == 'NoSidebar'
              ? 'pp-order-summary--no-sidebar'
              : ''
          "
        >
          <h2 class="pp-order-summary__title">{{ $t('common.orderDetails') }}</h2>
          <!-- START ORDER SUMMARY LIST -->
          <div class="pp-order-summary__list">
            <!-- PRODUCT -->
            <div
              v-if="data.type == 'simple'"
              class="pp-order-summary__list__item"
            >
              <div
                class="common-font product-name product-title-shrink-checkout nosidebar"
              >
                {{ data.name }}
                <span
                  >({{
                    data.enableProductOptions &&
                    data.addQuantity &&
                    cartQuantity >= 1
                      ? cartQuantity
                      : noProductOptionQty
                  }}x)</span
                >
              </div>
              <div
                v-if="data.type == 'simple'"
                class="text-right justify-between"
              >
                <div class="common-font regular_price">
                  Rp{{ price | g_number_format }}
                </div>
                <div
                  class="common-font sale_price text-danger left"
                  style="text-decoration: line-through"
                  v-if="data.priceCompare && data.type == 'simple'"
                >
                  Rp{{ (quantity * data.price) | g_number_format }}
                </div>
                <div
                  v-else-if="parseInt(priceWithoutWhosale) !== parseInt(price)"
                  class="text-danger def common-font sale_price"
                  style="text-decoration: line-through"
                >
                  Rp{{ priceWithoutWhosale | g_number_format }}
                </div>
              </div>
            </div>

            <!-- VARIANT ORDER -->
            <div
              v-if="data.type != 'simple'"
              class="pp-order-summary__list__item pp-order-summary__list__item--variant-order"
            >
              <div
                class="common-font product-name product-title-shrink-checkout nosidebar"
              >
                {{ data.name }}
                <span v-if="totalQtyMultipleVariant > 0">({{ totalQtyMultipleVariant }}x)</span>
              </div>
              <!-- VARIANT ORDER SINGLE -->
              <template
                v-if="
                  !data.multiplyVariations ||
                  data.type == 'simple' ||
                  (data.multiplyVariations && data.cartOrder)
                "
              >
                <template
                  v-if="
                    cartSummary &&
                    cartSummary.length > 0 &&
                    data.multiplyVariations
                  "
                >
                  <template v-for="(elem, i) in cartSummary">
                    <div
                      v-if="elem.qty"
                      v-bind:key="i"
                      class="item--variant-order__cart--qty"
                    >
                      <div class="item--variant-order__elem-qty__info">
                        <div class="flex justify-start" style="flex: 1 1 auto;">
                          <div style="padding-right: 5px">
                            ({{
                              cartSummary[i] ? cartSummary[i].qty : elem.qty
                            }}x)
                          </div>
                          <div class="single-variant">
                            <div
                              v-for="(elemChild, j) in elem.items"
                              v-bind:key="j + 'p'"
                            >
                              {{ j.substring(0, j.length - 1) }}
                              <span style="font-weight: 600;">{{ elemChild.name }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- <div style="text-align: end">
                          <div
                            class="text-danger"
                            style="text-decoration: line-through"
                            v-if="
                              getSubPrice(elem) !==
                              elem.priceOriginal * elem.qty
                            "
                          >
                            Rp{{
                              (elem.priceOriginal * elem.qty) | g_number_format
                            }}
                          </div>
                          <div>Rp{{ getSubPrice(elem) | g_number_format }}</div>
                        </div> -->
                        <div v-if="elem.price == 0">
                          Rp{{ (elem.priceOriginal * elem.qty) | g_number_format }}
                        </div>
                        <div v-if="elem.priceOriginal && elem.price > 0">
                          <div style="text-align: end">
                            <div>
                              Rp{{ getSubPrice(elem) | g_number_format }}
                            </div>
                            <div
                              class="text-danger"
                              style="text-decoration: line-through"
                              v-if="
                                getSubPrice(elem) !==
                                elem.priceOriginal * elem.qty
                              "
                            >
                              Rp{{ (elem.priceOriginal * elem.qty) | g_number_format }}
                            </div>
                          </div>
                        </div>

                        <!-- VARIANT DELETE BUTTON -->
                        <div
                          v-if="cartSummary.length > 1"
                          class="delete-variant text-right ml-5px removeFromCart"
                        >
                          <img
                            @click="removeFromCart(i)"
                            src="@/assets/icons/Delete-grey.svg"
                            alt=""
                            class="pointer"
                          />
                        </div>
                        
                      </div>
                    </div>
                  </template>
                </template>

                <div
                  class="item--variant-order__cart--default flex justify-between"
                  v-else
                >
                  <div class="flex justify-start">
                    <div style="padding-right: 5px">
                      ({{
                        data.addQuantity && cartQuantity >= 1
                          ? cartQuantity
                          : noProductOptionQty
                      }}x)
                    </div>
                    <div class="single-variant left">
                      <div
                        :key="l + 'p'"
                        v-for="(vprice, l) in Object.keys(
                          payload.variablePrices
                        )"
                      >
                        {{ vprice.substring(0, vprice.length - 1) }}
                        <span style="font-weight: 600;">{{ payload.variablePrices[vprice].name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-right w-50 ct-1">
                    <div>Rp{{ price | g_number_format }}</div>
                    <div
                      class="text-danger"
                      style="text-decoration: line-through"
                      v-if="parseInt(priceWithoutWhosale) !== parseInt(price)"
                    >
                      Rp{{ priceWithoutWhosale | g_number_format }}
                    </div>
                  </div>
                </div>
              </template>

              <!-- VARIANT ORDER MULTIPLY VARIATION -->
              <template v-else>
                <template v-for="(attr, i) in data.variablePrices">
                  <div
                    v-if="attr.qty > 0"
                    :key="'f' + i"
                    class="item--variant-order__cart--qty"
                  >
                    <div class="item--variant-order__elem-qty__info">
                      <div class="flex justify-start" style="flex: 1 1 auto;">
                        <div style="padding-right: 5px">({{ attr.qty }}x)</div>
                        <div class="single-variant">
                          <div 
                            v-for="(elemChild, j) in attr.attrs"
                            v-bind:key="elemChild.name + elemChild.value + j"
                          >
                            {{elemChild.name.substring(0, elemChild.name.length - 1) }}
                            <span style="font-weight: 600;">{{ elemChild.value }}</span>
                          </div>
                          <!-- {{
                            attr.attrs
                              .map(
                                (n) =>
                                  n.name.substring(0, n.name.length - 1) +
                                  " : " +
                                  n.value
                              )
                              .join(",")
                          }} -->
                        </div>
                      </div>
                      <div style="text-align: end">
                        <div>
                          Rp{{ getSubPrice(attr) | g_number_format }}
                        </div>
                        <div
                          v-if="getSubPrice(attr) !== attr.price * attr.qty"
                          class="text-danger"
                          style="text-decoration: line-through"
                        >
                          Rp{{ (attr.price * attr.qty) | g_number_format }}
                        </div>
                      </div>
                      <div
                        v-if="attr.qty > 0"
                        class="delete-variant text-right removeFromCart ml-5px"
                      >
                        <img
                          @click="deleteVariant(i)"
                          src="@/assets/icons/Delete-grey.svg"
                          alt=""
                          class="pointer"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>

            <!-- BUMP -->
            <div
              v-if="data.isBump && selectedbumpArray.length > 0"
              class="pp-order-summary__list__item pp-order-summary__list__item--bump"
              style="border-bottom-style: solid;"
            >
              <div class="item--bump__label">{{ $t('common.bumpProduct') }}</div>
              <div class="item--bump__list">
                <div
                  v-for="(data, i) in selectedbumpArray"
                  :key="i"
                  class="item--bump__list__product"
                >
                  <div>{{ data.bumpName }}</div>
                  <div class="flex items-center gap-10px flexshrink">
                    <div>Rp{{ data.bumpPrice | g_number_format }}</div>
                    <div
                      @click="deleteBumpProduct(i)"
                      role="button"
                      class="delete-variant text-right removeFromCart ml-10 flexshrink"
                    >
                      <img
                        src="@/assets/icons/Delete-grey.svg"
                        alt=""
                        class="pointer"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SHIPPING COST AUTOMATES -->
            <div
              v-if="
                data.addShipping &&
                (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
                parseInt(payload.deliveryPrice) +
                  parseInt( payload.payment === 'COD' ? data.shippingCOD.hideAdditionalCost ? data.shippingCOD.additionalCost : 0 : data.additionalCost) >
                  0
              "
              class="pp-order-summary__list__item"
              style="border-bottom-style: solid;"
            >
              <div>{{ $t('common.shippingCosts') }} ({{ weight | gramsToKilograms_format}}kg)</div>
              <div v-if="payload.deliveryPrice">
                Rp{{
                  (parseInt(payload.deliveryPrice) + (payload.payment === 'COD' ? (data.shippingCOD.hideAdditionalCost ? parseInt(data.shippingCOD.additionalCost) : 0) : (data.hideAdditionalCost ? parseInt(data.additionalCost) : 0)) || 0)
                  | g_number_format
                }}
              </div>
              <div v-else>
                {{ parseInt(0) | g_number_format }}
              </div>
            </div>
            <div v-if="
            (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
            data.addShipping && (payload.payment === 'COD' ? data.shippingCOD.shippingDiscount : data.shippingDiscount) &&
            (payload.payment === 'COD' ? data.shippingCOD.shippingDiscount.status : data.shippingDiscount.status) &&
                  parseInt(shippingDiscountValue) >
                  0
                  " class="pp-order-summary__list__item" style="border-bottom-style: solid;">
            <div>Diskon Pengiriman</div>
            <div>
              -Rp{{
                parseInt(shippingDiscountValue)
                | g_number_format
              }}
            </div>
          </div>

            <!-- ADDTIONAL SHIPPING COST -->
            <div
              class="pp-order-summary__list__item"
              style="border-bottom-style: solid;"
              v-if="
                (payload.payment === 'COD' ? !data.shippingCOD.hideAdditionalCost : !data.hideAdditionalCost) &&
                (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'automated' : data.shippingType === 'automated') &&
                data.addShipping &&
                (payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost) &&
                parseInt(payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost) != 0
              "
            >
              <div>{{ $t('common.additionalShippingCost') }}</div>
              <div>Rp{{ parseInt(payload.payment === 'COD' ? data.shippingCOD.additionalCost : data.additionalCost) | g_number_format }}</div>
            </div>

            <!-- SHIPPING COST FLAT -->
            <div
              v-if="
                data.addShipping &&
                (payload.payment === 'COD' ? data.shippingCOD.shippingType === 'flat' : data.shippingType === 'flat')
              "
              class="pp-order-summary__list__item"
              style="border-bottom-style: solid;"
            >
              <div>{{ $t('common.shippingCosts') }}</div>
              <div>
                Rp{{
                  parseInt((payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice) ? (payload.payment === 'COD' ? data.shippingCOD.flatShippingPrice : data.flatShippingPrice) : 0)
                    | g_number_format
                }}
              </div>
            </div>

            

            <!-- COD COST -->
            <div
              v-if="payload.payment == 'COD'"
              class="pp-order-summary__list__item flex justify-between"
              style="border-bottom-style: solid;"
            >
              <div>{{ $t('common.codFee') }}</div>
              <div>Rp{{ codPrice | g_number_format }}</div>
            </div>

            <!-- EPAY FEE -->
            <div
              v-if="
                this.payload.payment &&
                this.payload.payment != 'bankTransfer' &&
                this.payload.payment != 'COD' &&
                data.ePaymentFeePaidBy === 'buyer'
              "
              class="pp-order-summary__list__item flex justify-between"
              style="border-bottom-style: solid;"
            >
              <div>
                {{ paymentFeeMethodName(this.payload.payment) }}
                <p class="m-0 e-payment-admin-fee" v-if="this.payload.payment === 'gopay'">{{ $t('settings.adminEPayFeeGopay') }}</p>
                <p class="m-0 e-payment-admin-fee" v-else-if="this.payload.payment === 'QRIS'">{{ $t('settings.adminEPayFeeQRIS') }}</p>
                <p class="m-0 e-payment-admin-fee" v-else-if="this.payload.payment === 'credit_card'">{{ $t('settings.adminEPayFeeCreditCard') }}</p>
                <p class="m-0 e-payment-admin-fee" v-else>{{ $t('settings.adminEPayFeeOther') }}</p>
              </div>
              <div>Rp{{ calEpayFee() | g_number_format }}</div>
            </div>

            <!-- <div v-if="totalBumpOrderPrice && data.isBump" class="flex justify-between">
                  <div>Bump Price</div>
                  <div>Rp{{ totalBumpOrderPrice | g_number_format }}</div>
                </div> -->

            <!-- UNIQUE PRICE -->
            <div
              v-if="payload.uniquePrice && payload.payment == 'bankTransfer'"
              class="pp-order-summary__list__item"
              style="border-bottom-style: solid;"
            >
              <div>{{ $t('common.uniquePrice') }}</div>
              <div>
                Rp {{ this.payload.uniqueType == "Decrease" ? "-" : "" }}
                {{ payload.uniquePrice }}
              </div>
            </div>
            <!-- COUPON -->                  
            <div v-if="payload.discount.code" class="pp-order-summary__list__item" style="border-bottom-style: solid;">
              <div>{{payload.discount.code}}</div>         
          
              <div>-Rp{{ payload.discount.amount | g_number_format }}</div>
            </div>
            <div v-if="data.showCoupon && payload.automaticDiscount.code" class="pp-order-summary__list__item" style="border-bottom-style: solid;">
              <div>{{payload.automaticDiscount.code}}</div>        
          
              <div>-Rp{{ payload.automaticDiscount.amount | g_number_format }}</div>
            </div>
            <!-- VAT (PPN) -->
            <div v-if="data.VAT.isVAT && data.VAT.VATpercentage > 0" class="vat-price flex justify-between align-items-center">
              <div>PPN {{ data.VAT.VATpercentage }}%</div>
              <div>Rp{{ VATPrice | g_number_format }}</div>
            </div>
          </div>
          <!-- END ORDER SUMMARY LIST -->

          <!-- TOTAL -->
          <div class="pp-order-summary__total" style="border-top-style: solid;">
            <p class="pp-order-summary__total__label">{{ $t('common.total') }}</p>
            <p class="pp-order-summary__total__number">
              <template v-if="data.multiplyVariations">
                <template
                  v-if="
                    this.payload.payment &&
                    this.payload.payment != 'bankTransfer' &&
                    this.payload.payment != 'COD'
                  "
                >
                  Rp{{ totalwithEpayFee() | g_number_format }}
                </template>
                <template v-else>
                  Rp{{
                    (cartSummary.length > 0 && cartTotalPrice
                      ? Math.round(cartTotalPrice + (data.VAT && data.VAT.isVAT ? (cartTotalPrice * data.VAT.VATpercentage)/100 : 0 ))
                      : totalPrice) | g_number_format
                    }}
                </template>
              </template>
              <template v-else>
                <template
                  v-if="
                    this.payload.payment &&
                    this.payload.payment != 'bankTransfer' &&
                    this.payload.payment != 'COD'
                  "
                >
                  Rp{{ totalwithEpayFee() | g_number_format }}
                </template>
                <template v-else>Rp{{ totalPrice | g_number_format }}</template>
              </template>
            </p>
          </div>
          <!-- OLD CODE -->
          <!-- <div class="common-font order-summary-header mb-2">
            RINCIAN PESANAN:
          </div>
          <div
            class="flex justify-between mb-2"
            v-if="!cartSummary || cartSummary.length == 0"
          >
            <div class="common-font product-name product-title-shrink-checkout">
              {{ data.name }} ({{
                data.addQuantity && quantity ? quantity : noProductOptionQty
              }}x)
            </div>
            <div
              v-if="
                !data.multiplyVariations ||
                data.type == 'simple' ||
                (data.multiplyVariations && data.cartOrder)
              "
              class="text-right justify-between"
            >
              <div class="common-font regular_price">
                Rp{{ price | g_number_format }}
              </div>
              <div
                class="common-font sale_price text-danger right"
                style="text-decoration: line-through"
                v-if="data.priceCompare && data.type == 'simple'"
              >
                Rp{{
                  ((noProductOptionQty ? noProductOptionQty : quantity) *
                    data.price)
                    | g_number_format
                }}
              </div>
              <div
                v-else-if="parseInt(priceWithoutWhosale) !== parseInt(price)"
                class="text-danger def common-font sale_price"
                style="text-decoration: line-through"
              >
                Rp{{ priceWithoutWhosale }}
              </div>
            </div>
          </div>
          <div
            v-if="
              data.shippingType == 'automated' &&
              parseInt(payload.deliveryPrice) + parseInt(data.additionalCost) >
                0
            "
            class="flex justify-between right automated"
            :class="
              data.shippingType == 'automated' && payload.deliveryPrice
                ? 'mt-15'
                : ''
            "
          >
            <div>Biaya Pengiriman ({{ weight }}gr)</div>
            <div>
              Rp{{
                (parseInt(payload.deliveryPrice) +
                  parseInt(data.additionalCost))
                  | g_number_format
              }}
            </div>
          </div>
          <div
            v-if="
              data.shippingType == 'flat' &&
              parseInt(data.flatShippingPrice ? data.flatShippingPrice : 0) +
                parseInt(data.additionalCost) >
                0
            "
            class="flex justify-between"
            :class="data.shippingType == 'flat' ? 'mt-15' : ''"
          >
            <div>Biaya Pengiriman</div>
            <div>
              Rp{{
                (parseInt(data.flatShippingPrice ? data.flatShippingPrice : 0) +
                  parseInt(data.additionalCost))
                  | g_number_format
              }}
            </div>
          </div>
          <div
            v-if="payload.payment == 'COD'"
            class="flex justify-between"
            :class="payload.payment == 'COD' ? 'mt-15' : ''"
          >
            <div>Biaya COD</div>
            <div>Rp{{ codPrice | g_number_format }}</div>
          </div>
          <div
            v-if="data.type != 'simple'"
            :class="data.type != 'simple' ? 'mt-15' : ''"
          >
            <template
              v-if="
                !data.multiplyVariations ||
                data.type == 'simple' ||
                (data.multiplyVariations && data.cartOrder)
              "
            >
              <template
                v-if="
                  cartSummary &&
                  cartSummary.length > 0 &&
                  data.multiplyVariations
                "
              >
                <template v-for="(elem, i) in cartSummary">
                  <div
                    v-if="elem.qty && cartSummary[i]"
                    v-bind:key="i"
                    class="order-summary-list"
                  >
                    <div class="flex justify-between">
                      <div class="flex justify-start">
                        <div style="padding-right: 3px">({{ elem.qty }}x)</div>
                        <div class="single-variant">
                          <div
                            :key="l + 'p'"
                            v-for="(vprice, l) in Object.keys(elem.items)"
                          >
                            {{ vprice.substring(0, vprice.length - 1) }}:
                            {{ elem.items[vprice].name }}
                          </div>
                        </div>
                      </div>
                      <div style="text-align: end">
                        <div v-bind:key="i">
                          Rp {{ (elem.price * elem.qty) | g_number_format }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="elem.qty > 0"
                      class="delete-variant text-right mt-10"
                      @click="removeFromCart(i)"
                    >
                      <img src="@/assets/icons/Delete-grey.svg" alt="" />
                    </div>
                  </div>
                </template>
              </template>
              <div v-else class="flex justify-between">
                <div class="flex justify-start">
                  <div style="padding-right: 3px">
                    ({{ quantity > 1 ? quantity : noProductOptionQty }}x)
                  </div>
                  <div class="single-variant right">
                    <div
                      :key="l + 'p'"
                      v-for="(vprice, l) in Object.keys(payload.variablePrices)"
                    >
                      <div>
                        {{ vprice.substring(0, vprice.length - 1) }}:
                        {{ payload.variablePrices[vprice].name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>Rp{{ price | g_number_format }}</div>
              </div>
            </template>
            <div v-else>
              <template>
                <div
                  v-bind:key="i"
                  v-for="(attr, i) in data.variablePrices"
                  :style="
                    attr.qty > 0
                      ? 'padding-bottom: 4px; border-bottom: 1px dashed #ccc; padding-top: 7px'
                      : ''
                  "
                >
                  <div
                    v-if="attr.qty > 0"
                    :key="'f' + i"
                    class="flex justify-between mb-1"
                  >
                    <div class="w-50">
                      ({{ attr.qty }}x)
                      {{
                        attr.attrs
                          .map(
                            (n) =>
                              n.name.substring(0, n.name.length - 1) +
                              ": " +
                              n.value
                          )
                          .join(",")
                      }}
                    </div>
                    <div class="text-right w-50 ct-3">
                      <div
                        class="text-danger"
                        style="text-decoration: line-through"
                        v-if="getSubPrice(attr) !== attr.price * attr.qty"
                      >
                        Rp{{ (attr.price * attr.qty) | g_number_format }}
                      </div>
                      <div>Rp{{ getSubPrice(attr) | g_number_format }}</div>
                    </div>
                  </div>
                  <div
                    v-if="attr.qty > 0"
                    class="delete-variant text-right"
                    @click="deleteVariant(i)"
                  >
                    <img src="@/assets/icons/Delete-grey.svg" alt="" />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="payload.bump"
            class="flex justify-between"
            :class="payload.bump ? 'mt-15' : ''"
          >
            <div>{{ data.bumpName }}</div>
            <div>Rp{{ data.bumpPrice | g_number_format }}</div>
          </div>
          <hr class="mt-15" />
          <div
            v-if="payload.uniquePrice && payload.payment == 'bankTransfer'"
            class="flex justify-between"
          >
            <div>Unique Price</div>
            <div>
              Rp {{ this.payload.uniqueType == "Decrease" ? "-" : "" }}
              {{ payload.uniquePrice }}
            </div>
          </div>
          <div class="flex justify-between font-bold mt-15">
            <div class="common-font total title">Total</div>
            <div v-if="data.cartOrder" class="common-font total price">
              Rp{{
                (cartSummary.length > 0 ? cartTotalPrice : totalPrice)
                  | g_number_format
              }}
            </div>
            <div v-else>
              <div class="common-font total price">
                Rp{{ totalPrice | g_number_format }}
              </div>
            </div>
          </div> -->
        </div>
      </template>
     </no-ssr>
    </div>
   
    <!-- BUY BUTTON-->
    <template v-if="displayDiv(posName, 'buyButton')">
      <!--- CAPTCHA -->
      <div
        v-if="
          (data.themeType == 'Classic' ||
            (data.themeType != 'Classic' &&
              data.templateType != 'RightSidebar') ||
            (data.themeType != 'Classic' &&
              data.templateType == 'RightSidebar' &&
              !isSizeWindow)) &&
          showCaptcha
        "
        class="checkout-content--right__feature embed-checkout-right-button embed-buyButton flex align-items-center grid-gap15px"
      >
        <vue-hcaptcha
          :sitekey="googleCaptchaKey"
          @verify="verifyCaptcha"
          @expired="expiredMethod"
          @challenge-expired="expiredMethod"
        ></vue-hcaptcha>
      </div>

      <div v-if="data.summaryPlacement == 'Before Buy Button'">
        <div
          class="checkout-content--right__feature embed-checkout-right-button embed-buyButton flex align-items-center grid-gap15px"
          v-if="
            data.themeType == 'Classic' ||
            (data.themeType != 'Classic' &&
              data.templateType != 'RightSidebar') ||
            (data.themeType != 'Classic' &&
              data.templateType == 'RightSidebar' &&
              !isSizeWindow)
          "
        >
          <div
            v-if="isSizeWindow && cartSummary.length && data.cartOrder"
            style="
              flex: 0 0 56px;
              width: 56px;
              background: #ffffff;
              border: 1px solid #bfcee0;
              border-radius: 8px;
              max-height: 56px;
              height: 100%;
              padding: 17px;
            "
            @click="openCartSummary()"
          >
            <div
              class="flex justify-content-center align-items-center position-relative"
            >
              <div class="cart-counter">
                {{ cartSummary ? cartSummary.length : 0 }}
              </div>
              <img src="@/assets/icons/Cart-bucket.svg" alt="" />
            </div>
          </div>

          <div
            :style="
              isSizeWindow && cartSummary.length && data.cartOrder
                ? 'flex: 0 0 calc(100% - 70px); width: calc(100% - 70px);'
                : 'flex: 0 0 100%; width: 100%;'
            "
          >
          <div v-if="(data.variablePrices.length>0 && !isVariableItemSelected)" class="alert-warning1 mb-20 title-font error align-items-start d-flex"><img
                            class="mr-10"
                            src="@/assets/icons/error.svg"
                          />Kombinasi variasi ini tidak tersedia</div> 
            <div
              v-if="isDisabled"
              style="
                border-radius: 5px;
                background-color: #e0e0e0;
                min-height: 55px;
              "
              class="w-100 px-2 py-1 text-white text-cente"
            >
              <span
                style="color: rgba(0, 0, 0, 0.26); min-height: 55px"
                class="flex items-center justify-center"
                :class="{
                  'bold-buy-btn': buyButton.boldBuyButtonText,
                  'small-buy-btn': buyButton.buyButtonFontSize == 'Small',
                  'medium-buy-btn': buyButton.buyButtonFontSize == 'Medium',
                  'large-buy-btn': buyButton.buyButtonFontSize == 'Large',
                  'extra-large-buy-btn':
                    buyButton.buyButtonFontSize == 'Extra-Large',
                }"
              >
                {{ $t('common.stockEmpty') }}
              </span>
            </div>

            <div
              v-else-if="showCaptcha && !verifiedCaptcha"
              style="border-radius: 5px; background-color: #e0e0e0"
              class="w-100 px-2 py-1 text-white text-center"
            >
              <div
                style="color: rgba(0, 0, 0, 0.26)"
                class="flex items-center justify-center"
              >
                {{ buyButton.buyButtonText }}
              </div>
            </div>

            

            <!-- for show floating button  -->
            <button
              v-else-if="isSizeWindow && data.floatingButton"
              @click="() => !loadingPrice && save()"
              class="flex justify-center items-center pointer text-white m_float_btn"
              style="min-height: 55px; border-radius: 8px"
              :style="
                'background-color: ' +
                buyButton.buyButtonStyle +
                ';border: 1px solid ' +
                buyButton.buyButtonStyle +
                (isBuyButtonLoading | loadingPrice
                  ? '; opacity: 0.5; pointer-event: none; cursor: default;'
                  : '')
              "
              :disabled="isBuyButtonLoading || loadingPrice"
            >
              <div v-if="data.isAddIcon" class="d-flex justify-content-center">
                <vue-feather :type="buyButton.selectedIconName"></vue-feather>
              </div>
              <div v-else class="d-flex justify-content-center">
                <i  data-v-07452373="" data-v-62b2fe6e="" data-name="shopping-cart" data-tags="ecommerce,cart,purchase,store" data-type="shopping-cart" class="feather feather--shopping-cart d-flex"><svg data-v-07452373="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart feather__content"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg></i>
              </div>
            </button>

            <button
              v-else
              @click="() => !loadingPrice && save()"
              class="buy-button__default"
              :class="[
                buyButton.isAddIcon ? 'buy-button__default--show-icon' : '',
                isBuyButtonLoading ? 'buy-button__default--loading' : '',
                (data.variablePrices.length>0 && !isVariableItemSelected) ? 'buy-button__default--disabled' :''
              ]"
              :style="
                'background-color: ' +
                buyButton.buyButtonStyle +
                ';' +
                'border: 1px solid ' +
                buyButton.buyButtonStyle
              "
              :disabled="isBuyButtonLoading || loadingPrice || (data.variablePrices.length>0 && !isVariableItemSelected)"
            >
              <div class="buy-button__default__ic-loading">
                <img src="@/assets/icons/ic-loading-button.svg" alt="loading" />
              </div>
              <span
                :class="{
                  'bold-buy-btn': buyButton.boldBuyButtonText,
                  'small-buy-btn': buyButton.buyButtonFontSize == 'Small',
                  'medium-buy-btn': buyButton.buyButtonFontSize == 'Medium',
                  'large-buy-btn': buyButton.buyButtonFontSize == 'Large',
                  'extra-large-buy-btn':
                    buyButton.buyButtonFontSize == 'Extra-Large',
                }"
              >
                {{ buyButton.buyButtonText }}
              </span>
              <template v-if="buyButton.isAddIcon">
                <vue-feather :type="buyButton.selectedIconName"></vue-feather>
              </template>
            </button>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import config from "./../../config.js";
import Left from "./left.vue";
import Seal from "./seal.vue";
import FileUploader from "./../FileUploader.vue";
import SwitchGreenSelected from "./../SwitchGreenSelected.vue";
import AddressFill from "./../AddressFill.vue";
import SubAddress from "./../SubAddress.vue";
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
// import Cookies from "js-cookie";
import { debounce, get, keys } from "lodash";
import axios from "axios";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import moment from "moment";
import MSelect from "../ui/select/m-select.vue";
import { paymentFeeMethodName } from "@/helpers/epayMethodHelper";
import Coupon from "./components/coupon.vue";

export default {
  props: [
    "user",
    "codPrice",
    "data",
    "form",
    "weight",
    "loadingPrice",
    "isBuyButtonLoading",
    "payload",
    "submitted",
    "onChangeAction",
    "delivery",
    "templateType",
    "themeType",
    "price",
    "totalPrice",
    "priceWithoutWhosale",
    "isSizeWindow",
    "cartQuantity",
    "cartSummary",
    "embed",
    "popup",
    "noProductOptionQty",
    "cartTotal",
    "showCaptcha",
    "isMobile",
    "selectedbumpArray",
    "posName",
    "posIndex",
    "pos",
    "errors",
    "validate",
    "submited",
    "courierOptionDataFinal",
    "shippingDiscountValue",
    "filterAttributes",
    "isVariableItemSelected",
    "productImages",
    "variantImageIndex",
    "VATPrice",
    "discountLists",
    "totalQuantity"
  ],
  data() {
    return {
      moment,
      freeDeliveryPrice:'',
      baseUrl: config.api.replace("/api", ""),
      googleCaptchaKey: config.googleCaptchaKey,
      quantityOptions: [],
      verifiedCaptcha: false,
      buyButton: {},
      // cartSummary: [],
      isShowVariantImage: false,      
      deliveryCourierPrice: "",
      id: new Date().getTime(),
      showPaymentHideView: false,
      getSizedImageUrl,
      paymentFeeMethodName
    };
  },
  created() {
    if (this.pos.id && this.pos.id > 1) {
      this.buyButton = this.data.buyButtonChild[this.pos.uniqueName];
    } else {
      this.buyButton = this.data;
    }
  },
  components: {
    Seal: Seal,
    AddressFill,
    SubAddress,
    FileUploader: FileUploader,
    "selected-green-switch": SwitchGreenSelected,
    Left: Left,
    VueHcaptcha,
    MSelect,
    Coupon,
  },
  computed: {
    compfieldsPositionMobile() {
      return this.mobileVersion
        ? this.data.fieldsPositionMobile
        : ["singledummy"];
    },
    quantity: {
      get() {
         if(this.data.type == 'simple'){
          const index=this.data.requestedFields.findIndex(x=>x.field=='quantity' && x.active);       
          if(index > -1){
            return this.data.requestedFields[index].value;
          }else{
            return this.cartQuantity;
          }
        }else{
          return this.cartQuantity;
        }   
       
      },
      set(val) {
        this.$emit("updateCartQuantity", val);
      },
    },
    cartTotalPrice: {
      get() {
        return this.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },
    isDisabled() {
      if (this.data.trackQuantity && !this.data.continueSelling) {
        if (this.data.type == "simple") {
          return this.data.available < this.payload.quantity;
        } else {
          let disabled = false;
          this.data.variablePrices.map((one) => {
            if (one.available < one.qty) {
              disabled = true;
            }
          });
          return disabled;
        }
      } else {
        return false;
      }
    },
    datas() {
      return this.data.requestedFields.map((one) => {
        return {
          [one.field]: one.value,
        };
      });
    },
    totalQtyMultipleVariant() {
      if (this.cartSummary && this.cartSummary.length > 0 && this.data.multiplyVariations) {
        return (this.cartSummary || []).reduce((accumulation, item) => {
          return (accumulation || 0) + (item.qty || 0)
        }, 0)
      }

      return (this.data.variablePrices || []).reduce((accumulation, item) => {
        return (accumulation || 0) + (item.qty || 0)
      }, 0)
    },
    preOrderText() {
      return this.$t('common.preOrder').replace(/x/g, this.data.preOrder.packagingDueDay);
    },
  },
  methods: {
    formatPhone() {
      let phoneDataIndex = this.data.requestedFields.findIndex(
          (x) => x.field ==='phone'
        );
        if(phoneDataIndex && phoneDataIndex > -1){
          this.data.requestedFields[phoneDataIndex].value=this.data.requestedFields[phoneDataIndex].value.replaceAll('-', '').replaceAll('.', '')
        }
      
    },
    isCheapest(label, optionsData) {
      const prices = optionsData.map(option => parseInt(option.label.split('-')[2].replace('Rp', '').replace(',', '')));
      const currentPrice = parseInt(label.split('-')[2].replace('Rp', '').replace(',', ''));
      return Math.min(...prices) == currentPrice;
    },
    checkdisabled(discount) {   
      let discount_amount=0;          
      let totalQuantity=this.totalQuantity;
      let price =  this.price;  
      if(this.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }     
    
     if(discount.minimum_requirements.type=='minimum_purchase'){         
        if(Number(discount.minimum_requirements.value)>this.totalPrice){
          return discount_amount > 0 
        }        
      }else if(discount.minimum_requirements.type=='minimum_quantity'){       
        if(Number(discount.minimum_requirements.value)>totalQuantity){
          return discount_amount > 0 
        }        
      } 
      if(discount.discount_type=="moneyOffProduct"){
      
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=(Number(discount.discount_value.value));           
          }else{
            discount_amount =(price *Number(discount.discount_value.value)) / 100;                                       
                           
          }       
          
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
            discount_amount =(this.totalPrice *Number(discount.discount_value.value)) / 100;                             
                    
          }  
        }   
       
        return discount_amount > 0        
    },
    updateDiscountLists(discounts,replace){
      this.$emit('updateDiscountLists',discounts,replace)
    },
    selectDiscount(discount) {
      this.$emit('applyDiscount', discount ? discount.title : '')
    },
    clearDiscountCode() {     
      //e.stopPropagation()
      this.calcCart();
      this.$emit('applyDiscount', '')
      this.$emit('clearDiscount')
      
    },
    isHasOneWordLongText (text, maxLength=10){
      const words = text.split(' ');

      const result = words.some(word => {
          return word.length >= maxLength;
      });

      return result;
    },
    addBumpOrder(event,bumpData){      
      if(event.target.className!='vs-checkbox'){      
        const bumpIndex=this.selectedbumpArray.findIndex(x=>x.bump_id==bumpData.bump_id);
        if(bumpIndex==-1){
          this.selectedbumpArray.push(bumpData);
        }else{
          this.selectedbumpArray.splice(bumpIndex,1);
        }         
        event.preventDefault();      
     }
    },  
    truncatedText(text, getText = true) {
      const maxLength = 10;
      if (text.length > maxLength) {
        return getText ? text.slice(0, maxLength) + "..." : true;
      } else {
        return getText ? text + "..." : false;
      }
    },
    async estimate(){
      if(this.data && this.data.isShowStrikethrough){
        let val = this.payload.address
        let weight = this.weight
        weight = weight / 1000
        let shipFromAddress = this.data.multipleShipFrom && this.data.multipleShipFrom.length > 0 ? this.data.multipleShipFrom[0] : this.data.shipFrom
        let splitshipfrom = shipFromAddress.split('-');
        let origin = splitshipfrom.length > 0 ? splitshipfrom[0] : shipFromAddress
        let origin_id = splitshipfrom.length > 2 ? splitshipfrom[splitshipfrom.length-1] : splitshipfrom[0];      
       if(this.data.multiShipFromAddress && this.data.multiShipFromAddress.length > 0 && this.data.multiShipFromAddress[origin_id] && this.data.multiShipFromAddress[origin_id].address){
        origin_id=this.data.multiShipFromAddress[origin_id].address.PICKUP_AUTOFILL
       }

        let destination_to = val.customerAUTOFILL
        const to= val.DESTINATION_CODE
        let res = await axios.get(config.shippingUrl + 'order/allEstimatePublic', { params: { username: this.user.username, origin_id: origin_id, destination_id: destination_to, from: origin, to: to, weight: weight, courier:"jne" } })
        if (res.data.success) {
          const firstCourier = keys(res.data.data)[0];
          this.freeDeliveryPrice =  get(res.data.data, [firstCourier, 'price'], "");
        } 
        this.$forceUpdate();
      }
    },
    checkOption(name,attrval){    
     return !this.filterAttributes[name+"_"+attrval]
    },

    totalwithEpayFee() {
      return (this.cartSummary.length > 0 && this.cartTotalPrice
        ? this.cartTotalPrice
        : this.totalPrice) + this.calEpayFee();
    },
    calEpayFee() {
      let fee = 0;
      if (this.data && this.data.ePaymentFeePaidBy === 'buyer') {
        if (this.payload.payment == "bca_va" || this.payload.payment == "bni_va" || this.payload.payment == "echannel" || this.payload.payment == "bri_va") {
          fee = 4000;
        }
        if (this.payload.payment == "gopay") {
          fee = Math.round((this.totalPrice * 2) / 100);
        }
        if (this.payload.payment == "credit_card") {
          fee = Math.round(4000 + (this.totalPrice * 11) / 100);
        }
        if (this.payload.payment == "QRIS") {
          fee = Math.round((this.totalPrice * 0.7) / 100);
        }
      }
      return fee;
    },
    changeCouriePrice() {
      let price = this.deliveryCourierPrice;
      if (price) {
        //for getting origin id for the seller address
        let sellerOriginId = null;
        this.courierOptionDataFinal.forEach(option => {
          if (option.value == price) {
            sellerOriginId = option.origin_id;
          }
        });
        price = price.split("==");
        let priceLabel = this.courierOptionDataFinal.find(
          (x) => x.value == this.deliveryCourierPrice
        );
        priceLabel = priceLabel.label.split("-");
        this.payload.sellerOriginId = sellerOriginId;
        this.payload.deliveryCourier = price[0];
        this.payload.deliveryPrice = price[1];
        this.payload.deliveryType = priceLabel[1].trim()
          ? priceLabel[1].trim()
          : "REG";
        this.$forceUpdate();
      }
    },
    deleteVariant(index) {
      if (this.data.variablePrices) {
        this.data.variablePrices[index].qty = 0;
      }
      this.$forceUpdate();
    },
    deleteBumpProduct(index) {
      this.selectedbumpArray.splice(index, 1)
    },
    displayCopyright(index) {
      if (this.isSizeWindow) {
        if (this.compfieldsPositionMobile.length - 1 == index) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    displayDiv(fieldName, divName) {
      if (this.isSizeWindow) {
        if (fieldName == divName) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    changeText() {
      var text3 = document.getElementsByClassName("bumpText3");
      if (text3.length) {
        text3[0].removeAttribute("disabled");
      }
    },
    async verifyCaptcha(data) {
      let res = await axios.post(config.api + "auth/verifyGoogleCaptcha", {
        response: data,
      });
      if (res.data.data.success) {
        this.verifiedCaptcha = true;
      }
    },
    expiredMethod() {
      this.verifiedCaptcha = false;
    },
    calcCart() {
      const isPaymentCOD = this.payload.payment === 'COD';
      const shippingType = isPaymentCOD ? this.data.shippingCOD.shippingType : this.data.shippingType;
      const additionalCost = isPaymentCOD ? this.data.shippingCOD.additionalCost : this.data.additionalCost;
      const flatShippingPrice = isPaymentCOD ? this.data.shippingCOD.flatShippingPrice : this.data.flatShippingPrice;
      
      let price =
        0 +
        parseFloat(this.payload.deliveryPrice ? this.payload.deliveryPrice : 0);
      this.cartSummary.map(async (e) => {
        let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
        if (e.whosaleData) {
          e.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
              subPrice = one.price * parseInt(e.qty);
            }
            // return subPrice
          });
        }
        price = price + subPrice;
      });

      if (isPaymentCOD) {
        price = price + parseFloat(this.codPrice);
      }
      if (this.payload.uniquePrice && this.payload.payment == "bankTransfer") {
        price =
          price +
          (this.data.codeType == "Decrease"
            ? -this.payload.uniquePrice
            : this.payload.uniquePrice);
      }
      if (additionalCost && this.data.addShipping) {
        price = price + parseInt(additionalCost);
      }
      if (flatShippingPrice && shippingType == "flat") {
        price = price + parseInt(flatShippingPrice);
      }
      if (this.shippingDiscountValue && this.data.addShipping && shippingType == "automated" && this.shippingDiscountValue > 0) {
        price = price - parseFloat(parseFloat(this.shippingDiscountValue));
      }

      if (
        this.data.isBump &&
        this.data.bumpArray &&
        this.data.bumpArray.length >= 0 &&
        this.selectedbumpArray
      ) {
        // price = price + parseFloat(this.data.bumpPrice)
        this.selectedbumpArray.map((bumpData) => {
          //  const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            price =
              price + (bumpData.bumpPrice ? parseFloat(bumpData.bumpPrice) : 0);
          }
        });
      }
      if (typeof price == "string") {
        price = parseFloat(price);
      }
      // return parseInt(price.toFixed())
      this.$emit("updateTotalOriginal",price);     
      if(this.payload.discount["amount"] > 0){
        price = price-this.payload.discount["amount"]
      }    
      if(this.payload.automaticDiscount["amount"] > 0){
        price = price-this.payload.automaticDiscount["amount"]
      }   
      this.cartTotalPrice = parseInt(price.toFixed());
    },
    changeMode(param) {
      this.payload.paymentMode = param;
    },
    addToCart() {
      this.$emit("addToCart");
      setTimeout(() => {
        this.calcCart();
      }, 1000);
    },
    openCartSummary() {
      this.$emit("cartOrderSummary");
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        attr.whosaleData.map((one) => {
          if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
            subPrice = one.price;
          }
        });
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    changeAddress(val) {
      this.payload.address = val;
    },
    findLastIndex(array, searchKey, searchValue, label) {
      let revArr = array.slice().reverse();
      let gValue = revArr.findIndex((x) => {
        return x.label === label;
      });
      var index = revArr.findIndex((x, i) => {
        return x[searchKey] === searchValue && i > gValue;
      });
      var count = array.length - 1;
      var finalIndex = index >= 0 ? count - index : index;
      return finalIndex;
    },
    showFields(index) {
      if((this.data.nuxt2 && process.client) || !this.data.nuxt2){
      let checkLast =  this.data.requestedFields.findLastIndex((e) => e.active);
      checkLast = checkLast > -1 ? checkLast : 0;
      if (
        (this.data.requestedFields[checkLast].field != "address" &&
          (this.data.requestedFields[checkLast].value ||
            this.data.requestedFields[checkLast].value != "")) ||
        (this.data.requestedFields[checkLast].field == "address" &&
          ((this.data.requestedFields[checkLast].key == "ENTER_CITY_NAME" &&
            this.payload.address) ||
            (this.data.requestedFields[checkLast].key != "ENTER_CITY_NAME" &&
              this.payload.address &&
              this.data.requestedFields[checkLast] &&
              this.data.requestedFields[checkLast].key &&
              this.payload.address[
                this.data.requestedFields[checkLast].key.split("_")[0]
              ])))
      ) {
        this.enableSubmitButton = true;
      }
      if (this.data.formMode == "Progressive Form" && index != 0) {
        let initialindex = this.data.requestedFields.findIndex(
          (x) => x.label === this.data.initialFields
        );
        if (
          index <= initialindex &&
          this.data.requestedFields[initialindex].active === true
        ) {
          return true;
        }
        let currentIndex = index - 1;
        if (
          currentIndex > -1 &&
          !this.data.requestedFields[currentIndex].active
        ) {
          let lastIndex = this.findLastIndex(
            this.data.requestedFields,
            "active",
            true,
            this.data.requestedFields[index].label
          );
          currentIndex = lastIndex;
        }
        if (
          this.data.requestedFields[currentIndex] &&
          ((this.data.requestedFields[currentIndex].field != "address" &&
            (!this.data.requestedFields[currentIndex].value ||
              this.data.requestedFields[currentIndex].value == "")) ||
            (this.data.requestedFields[currentIndex].field == "address" &&
              (!this.payload.address ||
                (this.payload.address &&
                  ((this.data.requestedFields[currentIndex].key &&
                    !this.payload.address[
                      this.data.requestedFields[currentIndex].key == 'ENTER_CITY_NAME' ? 'CITY' : this.data.requestedFields[currentIndex].key.split("_")[0]
                    ]) ||
                    !this.payload.address)))))
        ) {
          return false;
        }
      }
    }
      return true;

    },
    save() {
      if(this.data.saveInfoWithoutEnable && this.data.isSaveInfoNextTime){
        this.saveDataForNexttime()
      }
      this.$emit("onSubmitted", true);
    },   
    increment(val, type) {    
     
      if (type == "multiVariant") {
        if (this.data.variablePrices[val]) {
          this.data.variablePrices[val].qty =
            parseInt(this.data.variablePrices[val].qty) + 1;
        }
      } else if (type == "singleVariant") {
        this.quantity = this.quantity + 1;
      }
      this.$forceUpdate();
    },
    decrement(val, type) {
      if (type == "multiVariant") {
        if (
          this.data.variablePrices[val].qty &&
          this.data.variablePrices[val].qty > 0
        ) {
          if (this.data.variablePrices[val].qty < 1) {
            return;
          } else {
            let variantData = this.data.variablePrices[val];
            variantData.qty = variantData.qty - 1;
            this.data.variablePrices.splice(val, 1, variantData);
          }
        }
      } else if (type == "singleVariant") {
        if (this.quantity < 2) {
          return;
        } else {
          this.quantity = this.quantity - 1;
        }
      }
      this.$forceUpdate();
    },
    saveDataForNexttime() {
      let subdomain = window.location.hostname;
      let customerInfoArr = {};
      let customerAddrArr = {};
      if (this.data.requestedFields && subdomain) {
        customerInfoArr[subdomain] = this.data.requestedFields;
        localStorage.setItem(
          "CheckoutCustomerInfo",
          JSON.stringify(customerInfoArr)
        );
      }
      if (this.payload.address != null) {
        customerAddrArr[subdomain] = this.payload.address;
        localStorage.setItem(
          "CheckoutAddressInfo",
          JSON.stringify(customerAddrArr)
        );
      }
    },
    getSelectedVariantImage() {
      this.isShowVariantImage = false;
      if (this.data.attributes.length) {
        let d = this.data.attributes[0];
        this.data.attributes[0].image = [];
        let selectedVariant = Object.values(this.payload.variablePrices);
        selectedVariant.shift();
        selectedVariant = selectedVariant.map((data) => {
          return data.name;
        });
        d.value.forEach((ef) => {
          let getSelectedVariant =
            ef + " / " + selectedVariant.toString().replace(/[,]/g, " / ");
          if (this.data.variablePrices.length) {
            this.data.variablePrices.forEach((elem) => {
              if (!this.isShowVariantImage && elem.image !== undefined) {
                this.isShowVariantImage = true;
              }
              if (getSelectedVariant == elem.name) {
                let image =
                  elem.image !== undefined
                    ? getSizedImageUrl(elem.image,'medium','product-images')
                    : require("@/assets/icons/variant-default-img.svg");
                this.data.attributes[0].image.push(image);
              }
            });
          }
        });
        this.$forceUpdate();
      }
    },
    openChooseVariantModal() {
      this.$emit("showMobile", this.cartSummary);
    },
    updateQuantity() {
      let keys = Object.keys(this.payload.variablePrices);
      let selectedVariant = this.payload.variablePrices
        ? keys.map((o) => this.payload.variablePrices[o].name).join(" / ")
        : undefined;
      let f = this.data.variablePrices.filter((e) => e.name == selectedVariant);
      if (f.length) {

        if(!(this.data.multiplyVariations && !this.data.cartOrder)){
          f[0].qty = f[0].qty == 0 ? 1 : f[0].qty;
        }       
        this.quantity = f.length ? f[0].qty : this.quantity;
      }
      this.$forceUpdate();
    },
    removeFromCart(index) {
      if (this.cartSummary.length) {
        this.$emit("updateCartSummary", index);
      }
      this.calcCart();
    },
    checkShowPaymentHideView() {
      this.showPaymentHideView = false;
      let bankTransferStatus = false;
      let EpaymentStatus = false;
      let CODStatus = false;

      const bankTransfer = this.data && this.data.paymentType.filter(
        (p) => p.active && p.type == "bankTransfer"
      );
      const Epayment = this.data && this.data.paymentType.filter(
        (p) => p.active && p.type == "E-Payment"
      );
      const COD = this.data.paymentType.filter(
        (p) => p.active && p.type == "COD"
      );

      if (bankTransfer && bankTransfer.length > 0) {
        //bankTransferStatus=this.data.bankTransferSettings.filter((p) => p.active).length==1;
        bankTransferStatus = true;
      }
      if (Epayment && Epayment.length > 0) {
        EpaymentStatus =
          this.data.epaymentSettings.filter((p) => p.active).length == 1;
      }
      if (COD && COD.length > 0) {
        CODStatus = true;
      }
      if (
        this.data.paymentType &&
        this.data.paymentType.filter((p) => p.active).length == 1 &&
        (bankTransferStatus || EpaymentStatus || CODStatus) &&
        this.data.paymentHideView &&
        this.data.paymentType
      ) {
        this.showPaymentHideView = true;
      }
    },
    shouldDisplayProductVariantOnCheckout(name, attrValue) {
      return !this.data.hideUnavailableProductVariant || (this.data.hideUnavailableProductVariant && !this.checkOption(name, attrValue));
    },
    filteredProductVariant(attrValues, attrName) {
      return attrValues.filter(attrValue => this.shouldDisplayProductVariantOnCheckout(attrName, attrValue));
    }
  },
  mounted() {
    if(this.data.multiplyVariations && !this.data.cartOrder){
      this.data.variablePrices=this.data.variablePrices.filter(x=>!x.delete);
    }

    for (let i = 1; i <= 100; i++) {
      this.quantityOptions.push(i.toString());
    }
    this.getSelectedVariantImage();
    this.updateQuantity();
    this.calcCart();
    this.deliveryCourierPrice =
      this.payload.deliveryCourier + "==" + this.payload.deliveryPrice;
    this.checkShowPaymentHideView();
    // if(this.data.saveInfoWithoutEnable && this.data.isSaveInfoNextTime){
    //  this.saveDataForNexttime()
    // }
  },
  watch: {
    'payload.address': {
      async handler() {
        this.estimate()
      },
      deep: true,
    },
    cartSummary: {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    selectedbumpArray: {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    payload: {
      handler() {
        this.$emit("updateCheckout");
      },
      deep: true,
    },
    "data.requestedFields": {
      handler() {
        this.$emit("updateCheckout");
        // this.validate();
        this.$emit("onValidate", true);
      },
      deep: true,
    },
    deliveryCourierPrice: {
      handler() {
        this.$emit("onValidate", true);
      },
      deep: true,
    },
    courierOptionDataFinal: {
      handler(val) {
        if(val && val.length>0){
          this.deliveryCourierPrice = val[0].value;
          this.id = new Date().getTime();
          setTimeout(() => {
            this.changeCouriePrice();
            this.$forceUpdate();
          }, 1000);
       }
      },
      deep: true,
    },
    "payload.variablePrices": {
      handler() {
        this.getSelectedVariantImage();
        if (this.data.addQuantity) {
          this.updateQuantity();
        }
      },
      deep: true,
    },
    "payload.discount.code": {
      handler(val) {
        if(val==''){
          this.clearDiscountCode()
        }       
      },
      deep: true,
    },
    delivery: {
      handler() {
        this.deliveryCourierPrice =
          this.payload.deliveryCourier + "==" + this.payload.deliveryPrice;
      },
      deep: true,
    },
    quantity: {
      handler: debounce(function (val) {
        let keys = Object.keys(this.payload.variablePrices);
        let dataVal = this.payload.variablePrices
          ? keys.map((o) => this.payload.variablePrices[o].name).join(" / ")
          : undefined;
        let ftr = this.data.variablePrices.filter(
          (attr) => attr.name == dataVal
        );
        ftr[0].qty = val;
        this.$forceUpdate();
      }, 500),
      deep: true,
    },
    "payload.payment": {
      handler(val) {
        this.calcCart();
        this.paymentFeeMethodName(val);
      },
      deep: true,
    },
    "payload.deliveryPrice": {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    "payload.bump": {
      handler() {
        this.calcCart();
      },
      deep: true,
    },
    errors: {
      handler(errors) {
        // validate quantity
        if (errors['zeroQTY'] && this.$refs['zeroQTY']) {
          this.$refs['zeroQTY'].scrollIntoView({ behavior: 'smooth' })
          return
        }
        // validate deliveryCourierPrice
        if (errors['deliveryCourierPrice'] && this.$refs['deliveryCourierPrice']) {
          this.$refs['deliveryCourierPrice'].scrollIntoView({ behavior: 'smooth' })
          return
        }
        const isPreviousError = Boolean(errors['zeroQTY']) || Boolean(errors['deliveryCourierPrice'])
        // update dynamic input
        for (let i = 0; i < this.data.requestedFields.length; i++) {
          const field = this.data.requestedFields[i].field
          if (!isPreviousError && errors[field] && this.$refs[field]) {
            const element = this.$refs[field][0]
            element && element.scrollIntoView({ behavior: 'smooth' });
            break;
          }
        }
      },
      deep: true,
    }
  },
  filters: {
    g_number_format(val) {
      return new Intl.NumberFormat().format(val);
    },
    gramsToKilograms_format(val) {
      return (val / 1000).toFixed(1);
    },
  },
};
</script>

<style lang="scss">
.vs-form-control.phone-input input[type=number]::-webkit-inner-spin-button, 
.vs-form-control.phone-input input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}
.cheapest-label{
  padding: 0px 5px;
  color: #FFFFFF;
  border-radius: 10px;
  background: #8A65F3;
  font-size: 12px;
}
.select-coupon {
  width: 100%;
  input[type=radio] {
    flex-shrink: 0;
    margin-right: 15px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 18px;
    height: 18px;
    border: 0.15em solid #BCCADC;
    border-radius: 50%;

    transition: border 50ms ease-out;
    align-self: center;
    display: grid;
    place-content: center;
    cursor: pointer;

    &:checked {
      border: 0.35em solid #2E47BA;
    }

    &:disabled {
      cursor: pointer;
      opacity: 0.6;

      &:not(:checked) {
        opacity: 0.4;
      }
    }
  }

  .m-select {
    &__trigger {
      height: 44px !important;
      font-size: 13px;
      font-weight: 400;

      &::after {
        background-position: right 8px top 9px !important;
      }

      &__close {
        background-position: right 12px top 13px;
      }
    }
    &__options {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      max-height: 375px;
      overflow-y: auto;
      box-shadow: 0px 5px 20px 0px #2035511A;
      border: none !important;

      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #F6F8FC;
        border: 2px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;  
      }
    }
    &__options__item {
      padding: 0px 0px 15px !important;
      border-bottom: 0.5px solid #E0E9F4 !important;
      border-radius: 0 !important;
      
      &:hover,
      &[data-selected=true] {
        color: initial !important;
        background: initial !important;
      }

      .gap-15px {
        gap: 15px;
      }
      .fs-13 {
        font-size: 13px;
      }
      .color-mute {
        color: #6C7E95 !important;
      }
      .fw-400 {
        font-weight: 400 !important;
      }
      .fw-600 {
        font-weight: 600 !important;
      }
      &:not(:first-of-type) {
        margin-top: 15px;
      }
      p.color-mute {
        font-size: 11px;
        margin-top: 2px !important;
      }
      p.color-primary {
        flex-shrink: 0;
      }
      span.color-mute {
        display: inline-block;
        margin-top: 2px;
        padding: 2px 5px;
        border: 1px solid #BFCEE0;
        font-size: 10px !important;
        line-height: 16px;
      }
      .m-0.fw-400.fs-13.color-primary {
        text-align: right;
      }
    }
  }
}
.e-payment-admin-fee {
  font-size: 11px;
  font-weight: 500;
  color: #6C7E95;
  margin-top: 2px !important;
}
.single-variant {
  overflow-wrap: anywhere;
  &>:not([hidden])~:not([hidden]) {
    margin-top: 3px !important;
    margin-bottom: 0px !important;
  }
}
.addressText-textarea {
  border: 1px solid #bfcee0;
  border-radius: 8px !important;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  font-family: 'Inter', sans-serif;
  background-color: #fff !important;
  padding: 7px 13px;
  width:100%;
}

.addressText-textarea::placeholder {
  color: #6C7E95;
  opacity: 1;
}

.focus-active-field .addressText-textarea:focus {
  outline: none !important;
  border: 2px solid #2E47BA !important;
}
.courierOptionsWithPrice {
  justify-content: space-between;
  align-items: center;
  h6.types-ofcourier {
    font-weight: 500;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  p.shipping-address {
    font-weight: 400;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  h6.shipping-price {
    font-weight: 600;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
}
</style>

<style scoped>
.pre-order {
  background-color: #EEF1FF;
  gap: 10px;
  padding: 8px 15px;
  border: 1px solid #2E47BA;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}
.OtherContent_wrapper {
  background: #eef0fa;
  padding: 10px 15px;
}
.flat-and-free .attributes {
  background-color: #fff !important;
  padding: 11px 15px !important;
}
.flat-free-title {
  color: #203551;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
  text-transform: capitalize;
}
.shippingType-title {
  border-radius: 4px;
  background: #e0e3ea;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  color: #62738a;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.48px;
  text-transform: capitalize;
}
.free-shippingType-title {
  border-radius: 4px;
  background: #04ac0d;
  color: #fff;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 5px;
}
.radio-form {
  padding: 8px 15px;
  border: 1px solid #bfcee0 !important;
  border-radius: 8px;
}

.options-wrap {
  background: #ffffff;
  border: 1px solid #bfcee0;
  border-radius: 8px;
  align-items: center;
}

.increDecre-btn-wrap {
  background-color: rgba(46, 71, 186, 0.1);
  border-radius: 0px 8px 8px 0px;
  padding: 4px;
  max-height: 40px;
  height: 100%;
  align-items: center;
}

/* ZERO REFERENCE MAYBE NEED TO CHECK IF NOT USE DELETE IT*/
/* Embed-Popup */
@media (max-width: 575px) {
  .container-fluid {
    padding: 0px !important;
  }
}

.bump-image img {
  border-radius: 8px;
}

.options-wrap .qty-input {
  opacity: 1;
}

.embed-rightGroup {
  margin-top: 10px !important;
}

.after_bg_payment {
  position: relative;
  padding: 15px 0px !important;
  z-index: 0;
}
.after_bg_payment::after {
  content: "";
  /* background: #eef0fa; */
  width: 111%;
  height: 100%;
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0;
  z-index: -1;
}

.bump-data-text {
  max-width: 400px;
  width: 100%;
  word-wrap: break-word;
}

.save-nextTime {
  width: 20px;
  margin-right: 10px;
}

.product-options {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #203551;
}
</style>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
.OtherContent_wrapper p {
  margin:0 !important;
}
.qty-input.no-bg input {
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #203551;
  text-align: center;
  background: transparent !important;
  border: none !important;
}

.minus-btn .bg-pm-btn,
.plus-btn .bg-pm-btn {
  background: #ffffff;
  border: 1px solid #e7edf4;
  border-radius: 8px;
  height: 32px;
  width: 32px;
}

.bg-pm-btn .horizontal {
  height: 1.3px;
  width: calc(100% - 20px);
  background: #6c7e95;
  margin: 14px auto;
  border-radius: 1px;
}

.bg-pm-btn .vertical {
  width: 1.3px;
  height: calc(100% - 20px);
  border-radius: 1px;
  background: #6c7e95;
  position: absolute;
  top: 10px;
  left: 14px;
}

.total-variant-info {
  background: #ffffff;
  border: 1px solid #bfcee0;
  border-radius: 8px;
  padding: 10px;
}

.m_float_btn {
  position: fixed;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  border-radius: 100% !important;
  min-width: 60px;
  min-height: 60px !important;
}

.bump {
  border-radius: 15px;
  padding: 15px;
  border: 1.5px dashed #f09126;
}

.save-information label.vs-checkbox-label {
  justify-content: left !important;
}
</style>
<style lang="scss">
@import "@/assets/styles/pages/checkout/components/form/right.scss";
</style>
